import { ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_CALENDARS = '/calendar/api/v1/Calendar?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_CALENDAR = '/calendar/api/v1/Calendar';
export const REST_URL_VIEW_DELETE_CALENDAR = '/calendar/api/v1/Calendar/{id}';
export const REST_URL_DATE_RANGE_STATUS = '/calendar/api/v1/Calendar/DateRange?calendarId={calendarId}&startDate={startDate}&endDate={endDate}';

export const ROUTE_NAME_CALENDAR = '/calendar';

export const RXFORM_CALENDAR = 'calendarPage';
export const RXFORM_CALENDAR_SET_DATES_DIALOG = 'setDatesDialog';

export const RXFIELD_CALENDAR_CALENDAR_NAME = 'name';
export const RXFIELD_CALENDAR_CALENDAR_DESCRIPTION = 'description';
export const RXFIELD_CALENDAR_CALENDAR_PARENT_CALENDARS = 'parents';
export const RXFIELD_CALENDAR_SELECTED_YEAR = 'selectedYear';
export const RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_BY = 'setBy';
export const RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_AS = 'setAs';
export const RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_MONTHS = 'months';
export const RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DAYS = 'days';
export const RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DATE = 'date';
export const RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DESCRIPTION = 'setDatesDescription';
export const RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_TAGS = 'setDatesTags';

export const RXSTATE_CALENDARS = 'calendars';
export const RXSTATE_CALENDAR_PAGE = 'uiCalendar';

export const INITIAL_ORDER_BY_CALENDAR = `name ${ORDER_SORT_ASCENDING}`;

export const MENUID_CALENDAR = 'MENUID_CALENDAR';
export const MENUID_CALENDAR_CALENDAR = 'MENUID_CALENDAR_CALENDAR';

export const SET_BY_WEEKDAY = 'Weekday';
export const SET_BY_INDIVIDUAL_DATE = 'IndividualDate';

export const SET_AS_WORKING_DAY = 'WorkingDay';
export const SET_AS_HOLIDAY = 'Holiday';
export const SET_AS_UNDEFINED = 'Undefined';

export const TYPE_ALL = 'All';
export const TYPE_PERSONAL = 'Personal';
export const TYPE_NON_PERSONAL = 'Non-Personal';

export const SET_BY_OPTIONS = [
  { label: SET_BY_WEEKDAY, value: SET_BY_WEEKDAY },
  { label: SET_BY_INDIVIDUAL_DATE, value: SET_BY_INDIVIDUAL_DATE },
];

export const SET_AS_OPTIONS = [
  { label: SET_AS_WORKING_DAY, value: SET_AS_WORKING_DAY },
  { label: SET_AS_HOLIDAY, value: SET_AS_HOLIDAY },
  { label: SET_AS_UNDEFINED, value: SET_AS_UNDEFINED },
];

export const TYPES_FILTER = [
  { label: TYPE_ALL, value: TYPE_ALL },
  { label: TYPE_PERSONAL, value: TYPE_PERSONAL },
  { label: TYPE_NON_PERSONAL, value: TYPE_NON_PERSONAL },
];
