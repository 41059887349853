import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_SCREEN = '/screen/api/v1/Screen?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';

export const REST_URL_MY_JOBS = '/userjob/api/v1/MyJob?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_VIEW_MY_JOB = '/userjob/api/v1/MyJob/{id}';
export const REST_URL_CANCEL_MY_JOB = '/userjob/api/v1/CancelMyJob/{id}';
export const REST_URL_DELETE_MY_JOB = '/userjob/api/v1/MyJob/{id}';
export const REST_URL_JOB = '/userjob/api/v1/Job?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=status=enabled';
export const REST_URL_DELETE_JOB = '/userjob/api/v1/Job/{id}';
export const REST_URL_VIEW_JOB = '/userjob/api/v1/Job/{id}';

export const ROUTE_NAME_USER_JOB = '/user-job';
export const ROUTE_NAME_MY_JOB = '/my-job';
export const ROUTE_NAME_JOB = '/job';

export const RXFORM_MY_JOB = 'myJobPage';
export const RXFORM_JOB = 'jobPage';

export const RXFIELD_MY_JOB_NAME = 'name';
export const RXFIELD_MY_JOB_DESCRIPTION = 'description';
export const RXFIELD_MY_JOB_USER_ID = 'user.id';
export const RXFIELD_MY_JOB_USER_NAME = 'user.fullName';
export const RXFIELD_MY_JOB_RESULT_TYPE = 'resultType';
export const RXFIELD_MY_JOB_JOB_STATUS = 'jobStatus';
export const RXFIELD_MY_JOB_JOB_STATUS_NOTES = 'jobStatusNotes';
export const RXFIELD_MY_JOB_PROGRESS = 'progress';
export const RXFIELD_MY_JOB_PROGRESS_NOTES = 'progressNotes';
export const RXFIELD_MY_JOB_START_TIME = 'startTime';
export const RXFIELD_MY_JOB_END_TIME = 'endTime';
export const RXFIELD_MY_JOB_DELETED_AFTER = 'deletedAfter';
export const RXFIELD_JOB_NAME = 'name';
export const RXFIELD_JOB_DESCRIPTION = 'description';
export const RXFIELD_JOB_USER_ID = 'user.id';
export const RXFIELD_JOB_USER_NAME = 'user.fullName';
export const RXFIELD_JOB_RESULT_TYPE = 'resultType';
export const RXFIELD_JOB_USER = 'user.fullname';
export const RXFIELD_JOB_JOB_STATUS = 'jobStatus';
export const RXFIELD_JOB_START_TIME = 'startTime';
export const RXFIELD_JOB_END_TIME = 'endTime';
export const RXFIELD_JOB_PROGRESS = 'progress';
export const RXFIELD_JOB_NOTES = 'notes';
export const RXFIELD_JOB_CREATED_DATE = 'createdDate';
export const RXFIELD_JOB_JOB_STATUS_NOTES = 'jobStatusNotes';
export const RXFIELD_JOB_JOB_PROGRESS_NOTES = 'progressNotes';
export const RXFIELD_JOB_DELETED_AFTER = 'deletedAfter';
export const RXFIELD_JOB_USER_PROFILE = 'user';

export const RXSTATE_MY_JOBS = 'myJobs';
export const RXSTATE_MY_JOB_PAGE = 'uiMyJob';
export const RXSTATE_JOBS = 'jobs';
export const RXSTATE_JOB_PAGE = 'uiJob';

export const INITIAL_ORDER_BY_MY_JOBS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_JOB = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_USER_JOB = 'MENUID_USER_JOB';
export const MENUID_USER_JOB_MY_JOB = 'MENUID_USER_JOB_MY_JOB';
export const MENUID_USER_JOB_JOB = 'MENUID_USER_JOB_JOB';

const JOB_STATUS_QUEING = 'Queuing';
const JOB_STATUS_IN_PROGRESS = 'InProgress';
const JOB_STATUS_CANCELLED = 'Cancelled';
const JOB_STATUS_ERROR = 'Error';
export const JOB_STATUS_FINISHED = 'Finished';

export const JOB_STATUS = [
  JOB_STATUS_QUEING,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_CANCELLED,
  JOB_STATUS_ERROR,
  JOB_STATUS_FINISHED,
];

const RESULT_TYPE_NONE = 'None';
const RESULT_TYPE_IMAGE = 'Image';
const RESULT_TYPE_VIDEO = 'Video';
const RESULT_TYPE_AUDIO = 'Audio';
const RESULT_TYPE_PDF = 'Pdf';
const RESULT_TYPE_CSV = 'Csv';
const RESULT_TYPE_SPREADSHEET = 'Spreadsheet';
const RESULT_TYPE_DOCUMENT = 'Document';
const RESULT_TYPE_PLAIN_TEXT = 'PlainText';
const RESULT_TYPE_BINARY = 'Binary';

export const RESULT_TYPE = [
  RESULT_TYPE_NONE,
  RESULT_TYPE_IMAGE,
  RESULT_TYPE_VIDEO,
  RESULT_TYPE_AUDIO,
  RESULT_TYPE_PDF,
  RESULT_TYPE_CSV,
  RESULT_TYPE_SPREADSHEET,
  RESULT_TYPE_DOCUMENT,
  RESULT_TYPE_PLAIN_TEXT,
  RESULT_TYPE_BINARY,
];
