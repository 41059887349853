import PropTypes from 'prop-types';

export const MenuItemShape = PropTypes.shape({
  id: PropTypes.string,
  level: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
  selected: PropTypes.bool,
  parentId: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
});

export const TableColumnShape = PropTypes.shape({
  title: PropTypes.string,
  field: PropTypes.string,
  sorting: PropTypes.bool,
});

export const FormInitialValueShape = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array,
  ]),
);

export const SimpleDataShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
});

export const SummaryCountShape = PropTypes.shape({
  name: PropTypes.string,
  count: PropTypes.number,
});

export const TotalCountShape = PropTypes.shape({
  downloading: PropTypes.bool,
  icon: PropTypes.node,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
});

export const PointShape = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number,
});

export const MoreMenuShape = PropTypes.shape({
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
});

export const StyleShape = PropTypes.oneOfType([
  PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])),
  ),
  PropTypes.string,
  PropTypes.number,
]);

export const DynamicFormFieldShape = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  image: PropTypes.string,
  validationMessage: PropTypes.string,
  defaultValue: PropTypes.string,
  order: PropTypes.number,
  required: PropTypes.bool,
  visible: PropTypes.bool,
  readOnly: PropTypes.bool,
  textMultiline: PropTypes.bool,
  textKeyboardType: PropTypes.string,
  fieldType: PropTypes.string,
  pickerMode: PropTypes.string,
  optionMode: PropTypes.string,
  optionValues: PropTypes.arrayOf(PropTypes.object),
  optionValueSeparator: PropTypes.string,
  mustUseCamera: PropTypes.bool,
  sectionId: PropTypes.string,
  sectionLabel: PropTypes.string,
  textMinLength: PropTypes.number,
  textMaxLength: PropTypes.number,
  textRegexValidation: PropTypes.string,
  intMinValue: PropTypes.number,
  intMaxValue: PropTypes.number,
  floatMinValue: PropTypes.number,
  floatMaxValue: PropTypes.number,
  dateTimeMinValue: PropTypes.string,
  dateTimeMaxValue: PropTypes.string,
  parent: PropTypes.objectOf(PropTypes.string),
  showIfParentValue: PropTypes.string,
  showIfParentValueLogicalOperator: PropTypes.string,
});

export const DynamicFormShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  visibilityStatus: PropTypes.string,
  validFrom: PropTypes.string,
  validUntil: PropTypes.string,
  fields: PropTypes.arrayOf(DynamicFormFieldShape),
});

export const AdditionalFieldParentFieldShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  image: PropTypes.string,
  fieldType: PropTypes.string,
  order: PropTypes.number,
  required: PropTypes.bool,
  visible: PropTypes.bool,
  readOnly: PropTypes.bool,
  textMultiline: PropTypes.bool,
});

export const AdditionalFieldShape = PropTypes.shape({
  appliedOn: PropTypes.string,
  createdBy: PropTypes.string,
  createdDate: PropTypes.string,
  dateTimeMaxValue: PropTypes.string,
  dateTimeMinValue: PropTypes.string,
  defaultValue: PropTypes.string,
  fieldType: PropTypes.string,
  floatMaxValue: PropTypes.number,
  floatMinValue: PropTypes.number,
  id: PropTypes.string,
  intMaxValue: PropTypes.number,
  intMinValue: PropTypes.number,
  label: PropTypes.string,
  lastModifiedBy: PropTypes.string,
  lastModifiedDate: PropTypes.string,
  name: PropTypes.string,
  optionMode: PropTypes.string,
  optionValueSeparator: PropTypes.string,
  optionValues: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.number,
  parent: AdditionalFieldParentFieldShape,
  pickerMode: PropTypes.string,
  placeHolder: PropTypes.string,
  readOnly: PropTypes.bool,
  remark: PropTypes.string,
  required: PropTypes.bool,
  sectionId: PropTypes.string,
  sectionLabel: PropTypes.string,
  showIfParentValue: PropTypes.string,
  status: PropTypes.string,
  textKeyboardType: PropTypes.string,
  textMaxLength: PropTypes.number,
  textMinLength: PropTypes.number,
  textMultiline: PropTypes.bool,
  textRegexValidation: PropTypes.string,
  validationMessage: PropTypes.string,
  visible: PropTypes.bool,
});

export const SectionAdditionalFieldShape = PropTypes.shape({
  sectionId: PropTypes.string,
  sectionLabel: PropTypes.string,
  data: PropTypes.arrayOf(AdditionalFieldShape),
});

const PositionShape = PropTypes.shape({
  lat: PropTypes.number,
  lng: PropTypes.number,
});

const BranchShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  area: PropTypes.array,
});

const UserShape = PropTypes.shape({
  id: PropTypes.string,
  fullName: PropTypes.string,
  employeeId: PropTypes.string,
  division: PropTypes.string,
  department: PropTypes.string,
});

export const ProfileShape = PropTypes.shape({
  startWorkingHour: PropTypes.string,
  startWorkingHourTime: PropTypes.string,
  startWorkingHourTimeZone: PropTypes.string,
  startWorkingHourMarginBeforeMinutes: PropTypes.number,
  startWorkingHourMarginAfterMinutes: PropTypes.number,
  earliestClockInDriftTimeMinutes: PropTypes.number,
  clockInTime: PropTypes.string,
  clockInDriftMinutes: PropTypes.number,
  clockInLatitude: PropTypes.number,
  clockInLongitude: PropTypes.number,
  clockInUserReportedLatitude: PropTypes.number,
  clockInUserReportedLongitude: PropTypes.number,
  clockInLocationId: PropTypes.string,
  clockInLocationName: PropTypes.string,
  clockInNotes: PropTypes.string,
  clockInStatus: PropTypes.string,
  clockInLocationStatus: PropTypes.string,
  endWorkingHour: PropTypes.string,
  endWorkingHourTime: PropTypes.string,
  endWorkingHourTimeZone: PropTypes.string,
  endWorkingHourMarginBeforeMinutes: PropTypes.number,
  endWorkingHourMarginAfterMinutes: PropTypes.number,
  autoClockOutTime: PropTypes.string,
  clockOutTime: PropTypes.string,
  clockOutDriftMinutes: PropTypes.number,
  clockOutLatitude: PropTypes.number,
  clockOutLongitude: PropTypes.number,
  clockOutUserReportedLatitude: PropTypes.number,
  clockOutUserReportedLongitude: PropTypes.number,
  clockOutLocationId: PropTypes.string,
  clockOutLocationName: PropTypes.string,
  clockOutNotes: PropTypes.string,
  clockOutBySystem: PropTypes.bool,
  clockOutStatus: PropTypes.string,
  clockOutLocationStatus: PropTypes.string,
  branch: BranchShape,
  profile: UserShape,
  additionalFields: PropTypes.array,
});

export const ClockInOutAreaShape = PropTypes.shape({
  position: PositionShape,
  profile: ProfileShape,
  id: PropTypes.string,
});

export const StyledTabShape = PropTypes.shape({
  label: PropTypes.string,
});
