import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Dialog,
  IconButton,
  LinearProgress,
  Typography, makeStyles,
  TablePagination,
} from '@material-ui/core';
import { Tune } from '@material-ui/icons';
import LocalizedString from '../../localization';
import GlobalizedLocalizedString from '../../../../localization';
import {
  COLOR_PRIMARY, PICKER_MODE_DATE, SIMPLE_DATE_FORMAT, COLOR_ICON_LIGHTER,
  COLOR_BODY_TEXT,
  COLOR_BACKGROUND_SECONDARY,
} from '../../../../constant';
import { AccentButton, DateTimePickerField } from '../../../../component';
import { toCurrency, toMoment } from '../../../../helper';
import { CustomErrorMessageShape } from '../../../attendance/type';

const useStyles = makeStyles(() => ({
  w500: {
    fontWeight: '500',
  },
  mb10: {
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex1: {
    flex: 1,
    display: 'flex',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  loading: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  filterButtonActions: {
    gap: '10px',
    marginRight: 8,
    marginTop: 10,
  },
  itemTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorPrimary: {
    backgroundColor: COLOR_ICON_LIGHTER,
  },
  barColorPrimary: {
    backgroundColor: COLOR_PRIMARY,
  },
  buttonOutline: {
    color: COLOR_BODY_TEXT,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
  },
  grayBackground: {
    backgroundColor: COLOR_BACKGROUND_SECONDARY,
  },
}));

const CustomErrorMessagesScreen = ({
  customErrorMessages, downloadingCustomErrorMessages, customErrorMessageDateRange,
  customErrorMessageFilterDialogVisibility, totalPages, onFilterIconPressed,
  onCloseDialogPressed, onApplyFilterPressed, onResetFilterPressed,
}) => {
  const [dateRange, setDateRange] = useState(customErrorMessageDateRange);
  const [page, setPage] = useState(0);
  const rowsPerPage = 9;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const classes = useStyles();

  useEffect(() => {
    setDateRange({
      from: customErrorMessageDateRange.from,
      to: customErrorMessageDateRange.to,
    });
  }, [customErrorMessageDateRange]);

  return (
    <>
      <div className={`${classes.row} ${classes.mb10}`}>
        <Typography
          variant="body1"
          className={`${classes.w500} ${classes.flex1}`}
        >
          {`${LocalizedString.analyticsScreen.labelCustomErrorMessages} (${toMoment(customErrorMessageDateRange.from).format(SIMPLE_DATE_FORMAT)} - ${toMoment(customErrorMessageDateRange.to).format(SIMPLE_DATE_FORMAT)})`}
        </Typography>
        <IconButton edge="end" onClick={onFilterIconPressed}>
          <Tune style={{ fill: COLOR_PRIMARY }} />
        </IconButton>
      </div>

      <div>
        <div className={classes.itemTitle}>
          <div style={{ flex: 2 }}>
            <span style={{ textTransform: 'uppercase' }}>
              {LocalizedString.analyticsScreen.labelCustomParameter}
            </span>
          </div>
          <div className={`${classes.flex1} ${classes.flexEnd}`}>
            <span style={{ textTransform: 'uppercase' }}>
              {LocalizedString.analyticsScreen.labelEventCounts}
            </span>
          </div>
          <div className={`${classes.flex1} ${classes.flexEnd}`}>
            <span style={{ textTransform: 'uppercase' }}>
              {LocalizedString.analyticsScreen.labelUsers}
            </span>
          </div>
        </div>
        <div
          className={`${classes.itemTitle} ${classes.mb10} ${classes.grayBackground}`}
          style={{ padding: '12px 0' }}
        >
          <div style={{ flex: 2 }}>
            <span>
              {`${customErrorMessages?.data?.length || 0} ${LocalizedString.analyticsScreen.labelItems}`}
            </span>
          </div>
          <div className={`${classes.flex1} ${classes.flexEnd}`}>
            <span style={{ textTransform: 'uppercase' }}>
              {toCurrency(customErrorMessages.totalEvent)}
            </span>
          </div>
          <div className={`${classes.flex1} ${classes.flexEnd}`}>
            <span style={{ textTransform: 'uppercase' }}>
              {toCurrency(customErrorMessages.totalUser)}
            </span>
          </div>
        </div>
      </div>

      {downloadingCustomErrorMessages ? (
        <div className={classes.loading}>
          <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
        </div>
      ) : (
        <>
          {customErrorMessages?.data?.slice(
              page * rowsPerPage, page * rowsPerPage + rowsPerPage,
            ).map((message) => (
              <div className={classes.mb10}>
                <div className={classes.itemTitle}>
                  <Typography
                    variant="body2"
                    style={{ flex: 2 }}
                    className={classes.w500}
                  >
                    {message.message}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${classes.flex1} ${classes.flexEnd}`}
                  >
                    {message.eventCount}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${classes.flex1} ${classes.flexEnd}`}
                  >
                    {message.userCount}
                  </Typography>
                </div>
                <LinearProgress
                  classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary,
                  }}
                  variant="determinate"
                  value={message.percentage}
                />
              </div>
            ))}
          <div className={`${classes.row} ${classes.flexEnd}`}>
            <TablePagination
              component="div"
              count={totalPages}
              page={page}
              onChangePage={(e, newPage) => handleChangePage(e, newPage)}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage=""
              rowsPerPageOptions={[]}
            />
          </div>
        </>
      )}

      <Dialog
        open={customErrorMessageFilterDialogVisibility}
        onClose={onCloseDialogPressed}
        fullWidth
        maxWidth="md"
      >
        <div style={{ padding: 30 }}>
          <Typography
            variant="body1"
            className={`${classes.w500} ${classes.flex1}`}
            style={{ marginLeft: 10, marginBottom: 8 }}
          >
            {GlobalizedLocalizedString.common.labelFilter}
          </Typography>
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelStartDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingCustomErrorMessages}
            value={dateRange.from}
            disableFuture
            onChangeDate={(date) => {
              setDateRange({
                from: date,
                to: dateRange.to,
              });
            }}
            InputProps={{ readOnly: true }}
          />
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelEndDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingCustomErrorMessages}
            value={dateRange.to}
            disableFuture
            onChangeDate={(date) => {
              setDateRange({
                from: dateRange.from,
                to: date,
              });
            }}
            InputProps={{ readOnly: true }}
          />

          <div className={`${classes.row} ${classes.flexEnd} ${classes.filterButtonActions}`}>
            <AccentButton
              onClick={onCloseDialogPressed}
              variant="text"
              caption={GlobalizedLocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              size="small"
              disabled={downloadingCustomErrorMessages}
            />
            <AccentButton
              onClick={onResetFilterPressed}
              variant="outlined"
              caption={GlobalizedLocalizedString.common.buttonCaptionReset}
              className={classes.buttonOutline}
              size="small"
              disabled={downloadingCustomErrorMessages}
            />
            <AccentButton
              type="submit"
              disableElevation
              caption={GlobalizedLocalizedString.common.buttonCaptionApply}
              size="small"
              onClick={() => onApplyFilterPressed(dateRange)}
              disabled={downloadingCustomErrorMessages}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CustomErrorMessagesScreen;

CustomErrorMessagesScreen.propTypes = {
  customErrorMessages: CustomErrorMessageShape.isRequired,
  downloadingCustomErrorMessages: PropTypes.bool.isRequired,
  customErrorMessageFilterDialogVisibility: PropTypes.bool.isRequired,
  customErrorMessageDateRange: PropTypes.object.isRequired,
  totalPages: PropTypes.number.isRequired,
  onFilterIconPressed: PropTypes.func.isRequired,
  onCloseDialogPressed: PropTypes.func.isRequired,
  onApplyFilterPressed: PropTypes.func.isRequired,
  onResetFilterPressed: PropTypes.func.isRequired,
};
