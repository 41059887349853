import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles, TablePagination, CircularProgress,
  IconButton, Dialog,
} from '@material-ui/core';
import { ArrowUpward, ArrowDownward, Tune } from '@material-ui/icons';
import {
  COLOR_C5221F,
  COLOR_188038,
  COLOR_PRIMARY,
  PICKER_MODE_DATE,
  SIMPLE_DATE_FORMAT,
  COLOR_BODY_TEXT,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalizedLocalizedString from '../../../../localization';
import { AccentButton, DateTimePickerField } from '../../../../component';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  w500: {
    fontWeight: '500',
  },
  mb10: {
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex1: {
    flex: 1,
    display: 'flex',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  flexStart: {
    justifyContent: 'flex-start',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  tableHeader: {
    textTransform: 'uppercase',
    borderBottomStyle: 'dashed',
    fontWeight: 500,
  },
  loading: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  titleSectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterButtonActions: {
    gap: '10px',
    marginRight: 8,
    marginTop: 10,
  },
  buttonOutline: {
    color: COLOR_BODY_TEXT,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
  },
}));

const UserEngagementByScreenName = ({
  userEngagementByScreenName, downloadingUserEngagementByScreenName, totalPages,
  onFilterIconPressed,
  userEngagementByScreenNameFirstDateRange, userEngagementByScreenNameSecondDateRange,
  userEngagementByScreenNameFilterDialogVisibility,
  onCloseDialogPressed, onResetFilterPressed, onApplyFilterPressed,
}) => {
  const classes = useStyles();

  const [firstDateRange, setFirstDateRange] = useState(userEngagementByScreenNameFirstDateRange);
  const [secondDateRange, setSecondDateRange] = useState(userEngagementByScreenNameSecondDateRange);
  const [page, setPage] = useState(0);
  const rowsPerPage = 9;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setFirstDateRange({
      from: userEngagementByScreenNameFirstDateRange.from,
      to: userEngagementByScreenNameFirstDateRange.to,
    });
    setSecondDateRange({
      from: userEngagementByScreenNameSecondDateRange.from,
      to: userEngagementByScreenNameSecondDateRange.to,
    });
  }, [userEngagementByScreenNameFirstDateRange, userEngagementByScreenNameSecondDateRange]);

  return (
    <>
      <div className={classes.titleSectionContainer}>
        <div className={classes.row}>
          <div>
            <Typography
              variant="body1"
              className={`${classes.mb10} ${classes.w500}`}
            >
              {`${LocalizedString.analyticsScreen.labelMobileAppUserEngagementsByScreenName} (${toMoment(firstDateRange.from).format(SIMPLE_DATE_FORMAT)} - ${toMoment(firstDateRange.to).format(SIMPLE_DATE_FORMAT)}) (${toMoment(secondDateRange.from).format(SIMPLE_DATE_FORMAT)} - ${toMoment(secondDateRange.to).format(SIMPLE_DATE_FORMAT)})`}
            </Typography>
          </div>
        </div>

        <IconButton edge="end" onClick={onFilterIconPressed}>
          <Tune style={{ fill: COLOR_PRIMARY }} />
        </IconButton>
      </div>
      <div className={classes.mb10}>
        <div className={`${classes.row} ${classes.spaceBetween}`}>
          <div style={{ flex: 2 }}>
            <span className={classes.tableHeader}>
              {LocalizedString.analyticsScreen.labelTitle}
            </span>
          </div>
          <div className={`${classes.flexEnd} ${classes.flex1}`} style={{ marginRight: 20 }}>
            <span className={classes.tableHeader}>
              {LocalizedString.analyticsScreen.labelPercentTotal}
            </span>
          </div>
          <div className={`${classes.flexStart} ${classes.flex1}`}>
            <span className={classes.tableHeader}>
              {LocalizedString.analyticsScreen.labelAverageTime}
            </span>
          </div>
        </div>
      </div>

      {
        downloadingUserEngagementByScreenName ? (
          <div className={classes.loading}>
            <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
          </div>
        ) : (
          <>
            {userEngagementByScreenName.slice(
              page * rowsPerPage, page * rowsPerPage + rowsPerPage,
            ).map((data) => (
              <div className={classes.mb10} key={data.screenName}>
                <div className={`${classes.row} ${classes.spaceBetween}`}>
                  <Typography
                    variant="body2"
                    className={classes.w500}
                    style={{ flex: 2 }}
                  >
                    {data.screenName}
                  </Typography>

                  <div className={`${classes.row} ${classes.flex1} ${classes.spaceBetween}`} style={{ marginRight: 18 }}>
                    <Typography variant="body2" style={{ marginRight: 10 }}>
                      {`${data.firstDateRange.engagementPercentage}%`}
                    </Typography>

                    <div className={`${classes.row}`}>
                      { data.percentChange.engagement.value !== 'Infinity' && (
                        data.percentChange.engagement.isDecreased
                          ? <ArrowDownward style={{ color: COLOR_C5221F, fontSize: 12 }} />
                          : <ArrowUpward style={{ color: COLOR_188038, fontSize: 12 }} />
                      )}

                      <Typography variant="body2">
                        {`${data.percentChange.engagement.value === 'Infinity' ? '-' : `${data.percentChange.engagement.value}%`}`}
                      </Typography>
                    </div>
                  </div>

                  <div className={`${classes.row} ${classes.flex1} ${classes.spaceBetween}`}>
                    <Typography variant="body2" style={{ marginRight: 10 }}>
                      {data.firstDateRange.averageTime}
                    </Typography>

                    <div className={`${classes.row}`}>
                      { data.percentChange.averageTime.value !== 'Infinity' && (
                        data.percentChange.averageTime.isDecreased
                          ? <ArrowDownward style={{ color: COLOR_C5221F, fontSize: 12 }} />
                          : <ArrowUpward style={{ color: COLOR_188038, fontSize: 12 }} />
                      )}

                      <Typography variant="body2">
                        {`${data.percentChange.averageTime.value === 'Infinity' ? '-' : `${data.percentChange.averageTime.value}%`}`}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className={`${classes.row} ${classes.flexEnd}`}>
              <TablePagination
                component="div"
                count={totalPages}
                page={page}
                onChangePage={(e, newPage) => handleChangePage(e, newPage)}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage=""
                rowsPerPageOptions={[]}
              />
            </div>
          </>
        )
      }

      <Dialog
        open={userEngagementByScreenNameFilterDialogVisibility}
        onClose={onCloseDialogPressed}
        fullWidth
        maxWidth="md"
      >
        <div style={{ padding: 30 }}>
          <Typography
            variant="body1"
            className={`${classes.w500} ${classes.flex1}`}
            style={{ marginLeft: 10, marginBottom: 8 }}
          >
            {GlobalizedLocalizedString.common.labelFilter}
          </Typography>
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelStartDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingUserEngagementByScreenName}
            value={firstDateRange.from}
            disableFuture
            onChangeDate={(date) => {
              setFirstDateRange({
                from: date,
                to: firstDateRange.to,
              });
            }}
            InputProps={{ readOnly: true }}
          />
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelEndDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingUserEngagementByScreenName}
            value={firstDateRange.to}
            disableFuture
            onChangeDate={(date) => {
              setFirstDateRange({
                from: firstDateRange.from,
                to: date,
              });
            }}
            InputProps={{ readOnly: true }}
          />
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelComparisonStartDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingUserEngagementByScreenName}
            value={secondDateRange.from}
            disableFuture
            onChangeDate={(date) => {
              setSecondDateRange({
                from: date,
                to: secondDateRange.to,
              });
            }}
            InputProps={{ readOnly: true }}
          />
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelComparisonEndDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingUserEngagementByScreenName}
            value={secondDateRange.to}
            disableFuture
            onChangeDate={(date) => {
              setSecondDateRange({
                from: secondDateRange.from,
                to: date,
              });
            }}
            InputProps={{ readOnly: true }}
          />

          <div className={`${classes.row} ${classes.flexEnd} ${classes.filterButtonActions}`}>
            <AccentButton
              onClick={onCloseDialogPressed}
              variant="text"
              caption={GlobalizedLocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              size="small"
              disabled={downloadingUserEngagementByScreenName}
            />
            <AccentButton
              onClick={onResetFilterPressed}
              variant="outlined"
              caption={GlobalizedLocalizedString.common.buttonCaptionReset}
              className={classes.buttonOutline}
              size="small"
              disabled={downloadingUserEngagementByScreenName}
            />
            <AccentButton
              type="submit"
              disableElevation
              caption={GlobalizedLocalizedString.common.buttonCaptionApply}
              size="small"
              onClick={() => onApplyFilterPressed(
                firstDateRange, secondDateRange,
              )}
              disabled={downloadingUserEngagementByScreenName}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default UserEngagementByScreenName;

UserEngagementByScreenName.propTypes = {
  userEngagementByScreenName: PropTypes.arrayOf(PropTypes.object).isRequired,

  downloadingUserEngagementByScreenName: PropTypes.bool.isRequired,
  userEngagementByScreenNameFilterDialogVisibility: PropTypes.bool.isRequired,

  totalPages: PropTypes.number.isRequired,

  userEngagementByScreenNameFirstDateRange: PropTypes.object.isRequired,
  userEngagementByScreenNameSecondDateRange: PropTypes.object.isRequired,

  onFilterIconPressed: PropTypes.func.isRequired,
  onCloseDialogPressed: PropTypes.func.isRequired,
  onResetFilterPressed: PropTypes.func.isRequired,
  onApplyFilterPressed: PropTypes.func.isRequired,
};
