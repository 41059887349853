import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  PAGE_MODE_ADD, PAGE_MODE_TABLE, PAGE_MODE_EDIT, MENUID_CALENDAR_CALENDAR, RXFORM_CALENDAR,
  RXFIELD_CALENDAR_CALENDAR_PARENT_CALENDARS, RXFIELD_CALENDAR_SELECTED_YEAR,
  INITIAL_ORDER_BY_CALENDAR, TYPE_ALL, TYPE_PERSONAL, TYPE_NON_PERSONAL,
} from '../../constant';
import {
  transformInitialValues, debounceSearch, transformCalendarParentCalendarsData,
  transformUserDropdownData, getPermission,
} from '../../helper';
import {
  setActiveSideMenuItem, setAdvancedFilterDialogSelectedFilterString, setAlertErrorMessage,
  clearFunctionalPageAdvancedFilterForm, setAlertConfirmationMessage, setFunctionalPageMode,
  clearCalendars, downloadCalendarsAsync, setCalendarTappedId, setCalendarSelectedPageSize,
  downloadDeleteCalendarAsync, addEditCalendarAsync, setCalendarSearchText,
  setCalendarSelectedOrderBy, addCalendar, setCalendarSelectedParentCalendars,
  setCalendarTableDataParentCalendars, setCalendarAdvancedFilterDialogSelectedFilterString,
  addingCalendarParentCalendar, setCalendarSelectedYear, downloadParentCalendarsAsync,
  clearParentCalendars, setParentCalendarsSearchText, setSetDatesDialogVisibility,
  setCalendarSelectedDate, setCalendarSelectedMonth, setCalendarSelectedSetAs,
  setCalendarSelectedSetBy, setCalendarSelectedDays, setCalendarSetDatesDescription,
  setCalendarDays, setCalendarDateInitialFocusedDate,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  clearProfiles, clearCalendarDateRangeStatus, downloadDateRangeStatusAsync,
  setCalendarActiveTab, setFunctionalPageAdvancedFilterForm,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import CalendarPage from './calendar.presentation';
import LocalizedString from '../../localization';
import GlobalizedString from '../../../../localization';

const transformParentCalendars = (data) => (data.length > 0 ? data.map((x) => (x.id
  ? { label: x.fullName, value: x.id } : x)) : []);

const getInitialValues = (state) => {
  const { calendars, uiCalendar, uiFunctionalPage } = state;
  const { tappedId, tableDataParentCalendars, selectedYear } = uiCalendar;
  const { pageMode } = uiFunctionalPage;

  const defaultYear = new Date().getFullYear();
  const selectedYearValue = selectedYear !== null
    ? selectedYear : { label: defaultYear.toString(), value: defaultYear };

  const found = tappedId !== '' && pageMode !== PAGE_MODE_TABLE
    ? calendars.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0 ? transformInitialValues(found, {
    name: found.name !== null ? found.name : '',
    description: found.description !== null ? found.description : '',
    parents: tableDataParentCalendars?.length > 0
      ? transformParentCalendars(tableDataParentCalendars) : [],
    selectedYear: selectedYearValue,
  }) : {
    name: '',
    description: '',
    parents: tableDataParentCalendars?.length > 0 ? tableDataParentCalendars : [],
    selectedYear: selectedYearValue,
  };
  return initVal;
};

const getYears = () => {
  const year = new Date().getFullYear();
  const firstYear = year - 5 - 1;
  return Array.from({ length: 11 }, (v, i) => {
    const yearItem = firstYear + i + 1;
    return { label: yearItem.toString(), value: yearItem };
  });
};

const getFilteredParentCalendars = (state) => {
  const { uiCalendar, parentCalendars } = state;
  const { tappedId } = uiCalendar;
  const { data } = parentCalendars;

  const transformedParentCalendars = transformCalendarParentCalendarsData(data);

  if (tappedId) {
    const result = transformedParentCalendars.filter((item) => item.value !== tappedId);
    return result;
  }
  return transformedParentCalendars;
};

export const getTableDataParentCalendars = (state) => {
  const { tableDataParentCalendars } = state.uiCalendar;

  return tableDataParentCalendars.length > 0
    ? tableDataParentCalendars.map((data) => data) : [];
};

const editPermissionName = 'CALENDAR_EDIT_CALENDAR';
const deletePermissionName = 'CALENDAR_DELETE_CALENDAR';

const mapStateToProps = (state) => {
  const currentParentCalendars = state.uiCalendar.tableDataParentCalendars;
  const parentCalendars = getFilteredParentCalendars(state);
  return {
    users: transformUserDropdownData(state.profiles.data),
    pageMode: state.uiFunctionalPage.pageMode,
    addingEditing: state.uiCalendar.addingEditing,
    downloading: state.uiCalendar.downloading,
    downloadingDeleting: state.uiCalendar.downloadingDeleting,
    addingParentCalendar: state.uiCalendar.addingParentCalendar,
    loadingUser: state.uiProfile.downloading,
    initialValues: getInitialValues(state),
    tappedId: state.uiCalendar.tappedId,
    parentCalendars: currentParentCalendars.length > 0
      ? parentCalendars.filter((x) => currentParentCalendars.every((y) => y.value !== x.value))
      : parentCalendars,
    tableDataParentCalendars: getTableDataParentCalendars(state),
    loadingParentCalendars: state.uiCalendar.downloading,
    selectedParentCalendars: state.uiCalendar.selectedParentCalendars,
    tappedParentCalendar: state.calendars.data[state.uiCalendar.tappedId],
    years: getYears(),
    setDatesDialogVisibility: state.uiCalendar.setDatesDialogVisibility,
    activeTab: state.uiCalendar.activeTab,
    searchBarText: state.uiCalendar.searchBarText,
    filterString: state.uiCalendar.filterString,
    selectedPageSize: state.uiCalendar.selectedPageSize,
    orderBy: state.uiCalendar.orderBy,
    selectedPageNo: state.calendars.meta.currentPage,
    initFilterString: state.uiFunctionalPage.filterString,
    advancedFilterForm: state.uiFunctionalPage.advancedFilterForm,
    downloadingDateRangeStatus: state.uiCalendar.downloadingDateRangeStatus,
    deletePermission: getPermission(state, deletePermissionName),
    editPermission: getPermission(state, editPermissionName),
    deletePermissionName,
    editPermissionName,
  };
};

const searchParentCalendarDebounce = debounceSearch((dispatch) => {
  dispatch(clearParentCalendars());
  dispatch(downloadParentCalendarsAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const resetSelectedYear = (dispatch) => {
  const defaultYear = new Date().getFullYear();
  dispatch(setCalendarSelectedYear({ label: defaultYear.toString(), value: defaultYear }));
  dispatch(change(
    RXFORM_CALENDAR, RXFIELD_CALENDAR_SELECTED_YEAR,
    { label: defaultYear.toString(), value: defaultYear },
  ));
};

const getUserFilterStringResult = (userFilterValue) => {
  switch (userFilterValue) {
    case TYPE_ALL:
      return '';
    case TYPE_PERSONAL:
      return 'user!=$NULL$';
    case TYPE_NON_PERSONAL:
      return 'user=$NULL$';
    default:
      return '';
  }
};

const mapDispatchToProps = (dispatch) => ({
  onAppear: (isFromDetail, searchBarText, filterString, selectedPageSize, orderBy,
    selectedPageNo, initFilterString, advancedFilterForm, setIsFromDetail) => {
    dispatch(setActiveSideMenuItem(MENUID_CALENDAR_CALENDAR));
    if (!isEmpty(advancedFilterForm)) {
      dispatch(setFunctionalPageAdvancedFilterForm(advancedFilterForm));
      dispatch(setAdvancedFilterDialogSelectedFilterString(initFilterString));
      dispatch(setCalendarAdvancedFilterDialogSelectedFilterString(filterString));
    } else {
      dispatch(clearFunctionalPageAdvancedFilterForm());
      dispatch(setAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setCalendarAdvancedFilterDialogSelectedFilterString(''));
    }
    dispatch(setCalendarSearchText(isFromDetail ? searchBarText || '' : ''));
    dispatch(clearCalendars());
    dispatch(setCalendarSelectedPageSize(selectedPageSize || 20));
    dispatch(setCalendarSelectedOrderBy(orderBy || INITIAL_ORDER_BY_CALENDAR));
    dispatch(downloadCalendarsAsync(selectedPageNo || 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    setIsFromDetail(false);
  },
  onBackPressed: () => {
    dispatch(setCalendarTappedId(''));
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CALENDAR));
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCalendarsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCalendarSelectedPageSize(pageSize));
    dispatch(downloadCalendarsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    dispatch(clearProfiles());
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteCalendarAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setFunctionalPageMode(PAGE_MODE_ADD));
    dispatch(reset(RXFORM_CALENDAR));
    dispatch(setCalendarTableDataParentCalendars([]));
    dispatch(setCalendarDays([]));
    dispatch(setCalendarSelectedParentCalendars(null));
    resetSelectedYear(dispatch);
    dispatch(setCalendarActiveTab(LocalizedString.calendarPage.labelIndividualView));
    dispatch(setParentCalendarsSearchText(''));
    dispatch(clearParentCalendars());
    dispatch(downloadParentCalendarsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setCalendarTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalizedString.common.msgDeleteConfirmation));
  },
  onViewButtonPressed: (id) => {
    dispatch(setCalendarTableDataParentCalendars([]));
    dispatch(setCalendarDays([]));
    resetSelectedYear(dispatch);
    dispatch(setCalendarTappedId(id));
    dispatch(setCalendarActiveTab(LocalizedString.calendarPage.labelIndividualView));
    dispatch(downloadDeleteCalendarAsync());
  },
  onRefresh: (pageSize) => {
    dispatch(setCalendarSelectedPageSize(pageSize));
    dispatch(clearCalendars());
    dispatch(downloadCalendarsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setParentCalendarsSearchText(''));
    dispatch(clearParentCalendars());
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString());
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadParentCalendarsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    let filterStringResult = filterString;

    const splittedFilterString = filterString.split('|').filter((x) => x !== '');
    const typeFilterString = splittedFilterString.find((x) => x.startsWith('type'));

    if (typeFilterString) {
      const typeFilterStringValue = typeFilterString.split('=')[1];
      const typeFilterStringResult = getUserFilterStringResult(typeFilterStringValue);
      const filteredSplittedFilterString = splittedFilterString.filter((x) => !x.startsWith('type'));
      if (typeFilterStringResult) {
        filteredSplittedFilterString.push(typeFilterStringResult);
      }
      filterStringResult = filteredSplittedFilterString.join('|');
    }
    const text = filterStringResult.replace(/user/, 'user.id').replace(/type/, 'user');
    dispatch(
      setCalendarAdvancedFilterDialogSelectedFilterString(text),
    );
    dispatch(clearCalendars());
    dispatch(downloadCalendarsAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_CALENDAR));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setCalendarAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCalendarSelectedOrderBy(INITIAL_ORDER_BY_CALENDAR));
    dispatch(clearCalendars());
    dispatch(downloadCalendarsAsync(1)).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });
  },
  onSavePressed: async (submitValue) => {
    try {
      await dispatch(addEditCalendarAsync(submitValue));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCalendarSearchText(text));
      dispatch(clearCalendars());
      await dispatch(downloadCalendarsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCalendarSelectedOrderBy(orderBy));
    dispatch(clearCalendars());
    dispatch(downloadCalendarsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onParentCalendarsOptionSelected: (option) => {
    if (option) {
      dispatch(setCalendarSelectedParentCalendars(option));
    } else {
      dispatch(setCalendarSelectedParentCalendars(null));
    }
  },
  onAddParentCalendarsPressed: async (
    defaultValue, newInitialValue, selectedOption, currentData,
  ) => {
    if (selectedOption) {
      try {
        dispatch(addingCalendarParentCalendar(true));
        if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
          const result = {
            ...currentData,
            parents: defaultValue.length > 0
              ? [...defaultValue, selectedOption] : [selectedOption],
          };
          const transformedResult = {
            ...result,
            parents: result.parents.map((data, index) => ({ ...data, order: index + 1 })),
          };
          await dispatch(addCalendar(transformedResult));
          dispatch(setCalendarTableDataParentCalendars(transformedResult.parents));
          dispatch(change(RXFORM_CALENDAR, RXFIELD_CALENDAR_CALENDAR_PARENT_CALENDARS,
            transformedResult.parents));
        } else {
          const newData = newInitialValue.length > 0
            ? [...newInitialValue.filter((x) => (x.value !== selectedOption.value)), selectedOption]
            : [selectedOption];
          const newDataWithOrder = newData.map((data, index) => ({ ...data, order: index + 1 }));
          await dispatch(setCalendarTableDataParentCalendars(newDataWithOrder));
          dispatch(change(
            RXFORM_CALENDAR, RXFIELD_CALENDAR_CALENDAR_PARENT_CALENDARS, newDataWithOrder,
          ));
        }
        dispatch(setParentCalendarsSearchText(''));
        dispatch(clearParentCalendars());
        dispatch(downloadParentCalendarsAsync(1))
          .catch((err) => dispatch(setAlertErrorMessage(err)));
      } finally {
        dispatch(setCalendarSelectedParentCalendars(null));
        dispatch(addingCalendarParentCalendar(false));
      }
    }
  },
  onChangeParentCalendarsText: async (text) => {
    dispatch(setParentCalendarsSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchParentCalendarDebounce(dispatch);
    }
  },
  onDeleteParentCalendarsPressed: (newValue, currentData) => {
    const transformedNewValue = newValue.map((v, index) => ({ ...v, order: index + 1 }));

    if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
      const result = { ...currentData, parents: transformedNewValue };
      dispatch(addCalendar(result));
      dispatch(setCalendarTableDataParentCalendars(result.parents));
      dispatch(setParentCalendarsSearchText(''));
      dispatch(clearParentCalendars());
      dispatch(downloadParentCalendarsAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      dispatch(setCalendarTableDataParentCalendars(transformedNewValue));
    }
    dispatch(change(
      RXFORM_CALENDAR,
      RXFIELD_CALENDAR_CALENDAR_PARENT_CALENDARS, transformedNewValue,
    ));
  },
  onShowYearOptionSelected: (option, activeTab) => {
    if (option) {
      dispatch(setCalendarSelectedYear(option));
      dispatch(change(RXFORM_CALENDAR, RXFIELD_CALENDAR_SELECTED_YEAR, option));

      if (activeTab === LocalizedString.calendarPage.labelCombinedlView) {
        dispatch(clearCalendarDateRangeStatus());
        dispatch(downloadDateRangeStatusAsync())
          .catch((err) => dispatch(setAlertErrorMessage(err)));
      }
    } else {
      dispatch(setCalendarSelectedYear(''));
      dispatch(change(RXFORM_CALENDAR, RXFIELD_CALENDAR_SELECTED_YEAR, ''));
    }
  },
  onEditPressed: (id) => {
    dispatch(setFunctionalPageMode(PAGE_MODE_EDIT));
    dispatch(setCalendarTableDataParentCalendars([]));
    dispatch(setCalendarSelectedParentCalendars(null));
    resetSelectedYear(dispatch);

    dispatch(setCalendarActiveTab(LocalizedString.calendarPage.labelIndividualView));
    dispatch(setCalendarTappedId(id));
    dispatch(downloadDeleteCalendarAsync()).catch((error) => {
      dispatch(setAlertErrorMessage(error));
    });

    dispatch(setParentCalendarsSearchText(''));
    dispatch(clearParentCalendars());
    dispatch(downloadParentCalendarsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSetDatesButtonPressed: (year) => {
    const dateObj = new Date();
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const date = `${day} ${month} ${year.label}`;

    dispatch(setCalendarSelectedSetBy(''));
    dispatch(setCalendarSelectedSetAs(''));
    dispatch(setCalendarSelectedMonth(''));
    dispatch(setCalendarSelectedDays(''));
    dispatch(setCalendarSelectedDate(''));
    dispatch(setCalendarSetDatesDescription(''));
    dispatch(setCalendarDateInitialFocusedDate(date));

    dispatch(setSetDatesDialogVisibility(true));
  },
  onTabPressed: (tab, activeTab) => {
    if (tab === activeTab) return;
    dispatch(setCalendarActiveTab(tab));
    if (tab === LocalizedString.calendarPage.labelIndividualView) {
      dispatch(downloadDeleteCalendarAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
    if (tab === LocalizedString.calendarPage.labelCombinedlView) {
      dispatch(clearCalendarDateRangeStatus());
      dispatch(downloadDateRangeStatusAsync())
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
