import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';
import {
  CircularProgress, Dialog, Typography, makeStyles,
} from '@material-ui/core';
import AnalyticsLineChart from '../../../../component/analytics-line-chart';
import LocalizedString from '../../localization';
import GlobalizedLocalizedString from '../../../../localization';
import { AccentButton, DateTimePickerField } from '../../../../component';
import {
  COLOR_BODY_TEXT,
  COLOR_PRIMARY,
  PICKER_MODE_DATE,
  SIMPLE_DATE_FORMAT,
} from '../../constant';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  w500: {
    fontWeight: '500',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex1: {
    flex: 1,
    display: 'flex',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  loading: {
    display: 'flex',
    height: 550,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    border: '.5px solid #dadce0',
    boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.03)',
  },
  filterButtonActions: {
    gap: '10px',
    marginRight: 8,
    marginTop: 10,
  },
  buttonOutline: {
    color: COLOR_BODY_TEXT,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
  },
}));

const TotalUsersByAppVersionsChart = ({
  appVersions, chartData, chartSeries, downloading,
  onFilterIconPressed, onAppear,
  usersByAppVersionsFilterDialogVisibility,
  usersByAppVersionsDateRange,
  onCloseDialogPressed, onResetFilterPressed,
  onApplyFilterPressed, onChangeStartDate,
  onChangeEndDate,
}) => {
  const classes = useStyles();
  const [dateRange, setDateRange] = useState(usersByAppVersionsDateRange);

  useEffect(onAppear, []);

  useEffect(() => {
    setDateRange({
      from: usersByAppVersionsDateRange.from,
      to: usersByAppVersionsDateRange.to,
    });
  }, [usersByAppVersionsDateRange]);

  if (chartData.length > 0 && !downloading) {
    return (
      <div>
        <AnalyticsLineChart
          data={chartData}
          title={`${LocalizedString.analyticsScreen.labelUsersByAppVersion} (${toMoment(dateRange.from).format(SIMPLE_DATE_FORMAT)} - ${toMoment(dateRange.to).format(SIMPLE_DATE_FORMAT)})`}
          loading={downloading}
          chartSeries={chartSeries}
          useFilterButton
          onFilterIconPressed={onFilterIconPressed}
        >
          { appVersions.map((appVersion) => (
            <LineSeries
              key={appVersion.toString()}
              name={appVersion.toString()}
              valueField={appVersion.toString()}
              argumentField="date"
            />
          ))}
        </AnalyticsLineChart>

        <Dialog
          open={usersByAppVersionsFilterDialogVisibility}
          onClose={onCloseDialogPressed}
          fullWidth
          maxWidth="md"
        >
          <div style={{ padding: 30 }}>
            <Typography
              variant="body1"
              className={`${classes.w500} ${classes.flex1}`}
              style={{ marginLeft: 10, marginBottom: 8 }}
            >
              {GlobalizedLocalizedString.common.labelFilter}
            </Typography>
            <DateTimePickerField
              label={LocalizedString.analyticsScreen.labelStartDate}
              pickerMode={PICKER_MODE_DATE}
              disabled={downloading}
              value={dateRange.from}
              disableFuture
              onChangeDate={(date) => onChangeStartDate(date, setDateRange, dateRange)}
              InputProps={{ readOnly: true }}
            />
            <DateTimePickerField
              label={LocalizedString.analyticsScreen.labelEndDate}
              pickerMode={PICKER_MODE_DATE}
              disabled={downloading}
              value={dateRange.to}
              disableFuture
              onChangeDate={(date) => onChangeEndDate(date, setDateRange, dateRange)}
              InputProps={{ readOnly: true }}
            />

            <div className={`${classes.row} ${classes.flexEnd} ${classes.filterButtonActions}`}>
              <AccentButton
                onClick={onCloseDialogPressed}
                variant="text"
                caption={GlobalizedLocalizedString.common.buttonCaptionCancel}
                className={classes.buttonText}
                size="small"
                disabled={downloading}
              />
              <AccentButton
                onClick={onResetFilterPressed}
                variant="outlined"
                caption={GlobalizedLocalizedString.common.buttonCaptionReset}
                className={classes.buttonOutline}
                size="small"
                disabled={downloading}
              />
              <AccentButton
                type="submit"
                disableElevation
                caption={GlobalizedLocalizedString.common.buttonCaptionApply}
                size="small"
                onClick={() => onApplyFilterPressed(dateRange)}
                disabled={downloading}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
  return (
    <div className={classes.loading}>
      <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
    </div>
  );
};

export default TotalUsersByAppVersionsChart;

TotalUsersByAppVersionsChart.propTypes = {
  appVersions: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartSeries: PropTypes.arrayOf(PropTypes.object).isRequired,

  downloading: PropTypes.bool.isRequired,
  usersByAppVersionsFilterDialogVisibility: PropTypes.bool.isRequired,

  usersByAppVersionsDateRange: PropTypes.object.isRequired,

  onAppear: PropTypes.func.isRequired,
  onFilterIconPressed: PropTypes.func.isRequired,
  onCloseDialogPressed: PropTypes.func.isRequired,
  onResetFilterPressed: PropTypes.func.isRequired,
  onApplyFilterPressed: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
};
