import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, isSupported } from 'firebase/messaging';

export const VAPID_KEY = 'BBu6JEbfF6DF5sIy0_pv8WbukutHKgO6dQXtQiygzQ-JYBbi9HIYsfR1xQRJb4_TMKWSUJCkZOq9tvi2vdSybWw';

const firebaseConfig = {
  apiKey: 'AIzaSyCvi9mfZkiqLiLNU_u9UnZA4acfgbad9tQ',
  authDomain: 'metrodata-event.firebaseapp.com',
  databaseURL: 'https://metrodata-event.firebaseio.com',
  projectId: 'metrodata-event',
  storageBucket: 'metrodata-event.appspot.com',
  messagingSenderId: '585042622276',
  appId: '1:585042622276:web:862a6617ebdd952c55c911',
  measurementId: 'G-BV4NFZ5N1X',
};

export const getFirebaseAnalytics = async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      const app = initializeApp(firebaseConfig);
      return getAnalytics(app);
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const messaging = (async () => {
  try {
    const analytics = await getFirebaseAnalytics();
    if (analytics) {
      return getMessaging();
    }
    return null;
  } catch (err) {
    return null;
  }
})();
