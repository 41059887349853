import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    analyticsMenu: {
      title: 'Analytics',
    },
    analyticsScreen: {
      title: 'Analytics',

      labelUsersByAppVersion: 'Users by App Version',
      labelMobileAppUserEngagementsByScreenName: 'Mobile App User Engagements by Screen Name',
      labelUsersByDeviceModel: 'Users by Device Model',
      labelCustomErrorMessages: 'Custom Event: application_error',
      labelActiveUsers: 'Active Users',
      labelActiveUsersinLast30Days: 'Active User in Last 30 Days',
      labelUserEngagementByScreenName: 'User engagement by Screen Name',
      labelUserByDeviceModel: 'User by device model',
      labelCustomParameter: 'Custom Parameter',
      labelEventCounts: 'Event Counts',
      labelItems: 'items',
      labelTitle: 'Title',
      labelPercentTotal: '% Total',
      labelAverageTime: 'Avg. time',
      labelPagination: '{pageRange} dari {totalPages}',
      labelStartDate: 'Start Date',
      labelEndDate: 'End Date',
      labelDeviceModel: 'Device Model',
      labelUsers: 'Users',
      labelComparisonStartDate: 'Comparison Start Date',
      labelComparisonEndDate: 'Comparison End Date',
      labelUserByCountry: 'Users by Country',
      labelCountry: 'Country',
      labelPageTitleAndScreenNames: 'Page Title and Screen Names',
      labelTotal: 'Total',
      labelViews: 'Views',
      labelViewsPerActiveUsers: 'Views Per Active Users',
      labelAverageEngagementTimePerActiveUser: 'Average Engagement Time Per Active User',
      labelWebsiteUserEngagements: 'Website User Engagements',
      labelURL: 'URL',
    },
  },
  id: {
    analyticsMenu: {
      title: 'Analitik',
    },
    analyticsScreen: {
      title: 'Analitik',

      labelUsersByAppVersion: 'Pengguna Berdasarkan Versi Aplikasi ',
      labelMobileAppUserEngagementsByScreenName: 'Keterlibatan Pengguna Aplikasi Seluler Berdasarkan Nama Layar.',
      labelUsersByDeviceModel: 'Pengguna Berdasarkan Model Perangkat',
      labelCustomErrorMessages: 'Event Khusus: application_error',
      labelActiveUsers: 'Pengguna Aktif',
      labelActiveUsersinLast30Days: 'Pengguna Aktif di 30 Hari Terakhir',
      labelUserEngagementByScreenName: 'Keterlibatan pengguna berdasarkan Nama Layar',
      labelUserByDeviceModel: 'Pengguna berdasarkan model perangkat',
      labelCustomParameter: 'Parameter Khusus',
      labelEventCounts: 'Jumlah Event',
      labelItems: 'item',
      labelTitle: 'Judul',
      labelPercentTotal: 'Total %',
      labelAverageTime: 'Waktu rata-rata',
      labelPagination: '{pageRange} of {totalPages}',
      labelStartDate: 'Tanggal Mulai',
      labelEndDate: 'Tanggal Selesai',
      labelDeviceModel: 'Model Perangkat',
      labelUsers: 'Pengguna',
      labelComparisonStartDate: 'Tanggal Mulai Pembanding',
      labelComparisonEndDate: 'Tanggal Selesai Pembanding',
      labelUserByCountry: 'Pengguna berdasarkan Negara',
      labelCountry: 'Negara',
      labelPageTitleAndScreenNames: 'Judul Halaman dan Nama Layar',
      labelTotal: 'Total',
      labelViews: 'Tampilan',
      labelViewsPerActiveUsers: 'Tampilan Per Pengguna Aktif',
      labelAverageEngagementTimePerActiveUser: 'Waktu Rata-Rata Interaksi Per Pengguna Aktif',
      labelWebsiteUserEngagements: 'Keterlibatan Pengguna Situs Web',
      labelURL: 'URL',
    },
  },
});

export default LocalizedString;
