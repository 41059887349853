import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, FormControl, FormHelperText, makeStyles, TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { COLOR_PRIMARY, COLOR_BODY_TEXT, COLOR_DANGEROUS } from '../constant';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: theme.spacing(1),
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_PRIMARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  textField: {
    display: 'flex',
    alignItems: 'stretch',
    fontSize: '14px',
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
}));

const AutocompleteTextField = ({
  options,
  disabled, error, freeSolo, hidden, loading,
  onChangeText, onOptionSelected,
  helperText, value,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error}>
          <Autocomplete
            autoSelect
            clearOnEscape
            value={value}
            disabled={disabled}
            loading={loading}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionLabel={(option) => (typeof option === 'object' ? option.label : (option || ''))}
            onChange={(event, option) => onOptionSelected(option)}
            onInputChange={(event, text) => onChangeText(text)}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Asynchronous"
                variant="outlined"
                className={classes.textField}
                disabled={disabled}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                {...props}
                InputLabelProps={{
                  style: {
                    fontSize: '14px',
                    '&.MuiOutlinedInput-notchedOutline': { fontSize: '14px' },
                  },
                }}
                size="small"
              />
            )}
            freeSolo={freeSolo}
          />
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default AutocompleteTextField;

AutocompleteTextField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  freeSolo: PropTypes.bool,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  onChangeText: PropTypes.func,
  onOptionSelected: PropTypes.func,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.string]),
};

AutocompleteTextField.defaultProps = {
  disabled: false,
  error: false,
  freeSolo: false,
  hidden: false,
  loading: false,
  onChangeText: () => {},
  onOptionSelected: () => {},
  helperText: '',
  value: '',
};
