import { reset } from 'redux-form';
import {
  addingEditingAnnotationDesign, addAnnotationDesignList, setAlertErrorMessage, setPDFTemplate,
  setFunctionalPageMode,
} from '../simple-action';
import { RXFORM_ANNOTATION_DESIGNER, PAGE_MODE_VIEW } from '../../../constant';
import { editAnnotation, submitAnnotation } from '../../../helper';
import downloadDeleteAnnotationDesignAsync from './downloadDeleteAnnotationDesignAsync';

export default (submitValue) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingAnnotationDesign(true));

    const { token } = getState().authentication;
    const { data } = getState().annotationDesigns;
    const { tappedId } = getState().uiAnnotationDesignList;
    const { templatePdf } = getState().uiUploadPdf;

    if (tappedId) {
      const response = await editAnnotation(tappedId, submitValue.name, submitValue.description,
        templatePdf, data, token);
      dispatch(addAnnotationDesignList(response));
      dispatch(downloadDeleteAnnotationDesignAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      const response = await submitAnnotation(submitValue.name, submitValue.description,
        templatePdf, data, token);
      dispatch(addAnnotationDesignList(response));
    }
  } finally {
    dispatch(setPDFTemplate(''));
    dispatch(reset(RXFORM_ANNOTATION_DESIGNER));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
    dispatch(addingEditingAnnotationDesign(false));
  }
};
