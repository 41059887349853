import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  makeStyles, Typography, Grid, Menu, MenuItem,
} from '@material-ui/core';
import { KeyboardArrowDownOutlined, DeleteOutline, EditOutlined } from '@material-ui/icons';
import LocalizedString from '../../localization';
import {
  COLOR_BACKGROUND, COLOR_BORDER_SHADOW_PDF,
  COLOR_HORIZONTAL_LINE, COLOR_TYPE_TEXT, PDF_TYPE_QR_CODE,
  PDF_TYPE_IMAGE, COLOR_TYPE_QR_CODE, COLOR_TYPE_IMAGE,
  PDF_TYPE_TEXT, COLOR_TEXT_ON_PRIMARY, COLOR_BORDER_DELETE,
  COLOR_PRIMARY, PAGE_MODE_VIEW,
  COLOR_DISABLED_CONTROL,
} from '../../constant';
import {
  AccentButton, VerticalSpacer, AlertBox,
} from '../../../../component';
import { AnnotationShape } from '../../type';
import { getImageValue } from '../../../../helper';
import AddEditDialog from '../add-edit-dialog';
import CodeEditorDialog from '../code-editor-dialog';
import ColorBox from '../color-box';

const textIcon = require('../../../../asset/text-icon.png');
const qrcodeIcon = require('../../../../asset/qr-code-icon.png');
const imageIcon = require('../../../../asset/image-icon.png');
const textIconBlack = require('../../../../asset/outlined-text-icon.png');
const qrcodeIconBlack = require('../../../../asset/outlined-qr-code-icon.png');
const imageIconBlack = require('../../../../asset/outlined-image-icon.png');

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(() => ({
  headerField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 18,
  },
  container: {
    backgroundColor: COLOR_BACKGROUND,
    padding: 10,
    flex: 1,
    margin: 10,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 0px 20px 0px',
  },
  buttonContainerShow: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonOutline: {
    fontSize: '12px',
    borderRadius: 5,
    margin: '0px 5px 0px 5px',
  },
  buttonCreate: {
    borderRadius: 10,
  },
  menuListContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  horizontalLine: {
    width: '100%',
    border: `1px solid ${COLOR_HORIZONTAL_LINE}`,
    margin: '15px 0px',
  },
  horizontalSpacer: {
    width: 10,
  },
  cardContainer: {
    border: `1px solid ${COLOR_BORDER_SHADOW_PDF}`,
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'row',
    margin: '15px 0px',
    overflow: 'hidden',
  },
  cardContentContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
  },
  colorRod: {
    width: 10,
  },
  cardTopContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  cardTopTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  cardTopTextValueTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  cardTopTextValueContent: {
    fontWeight: 600,
  },
  cardTopTextValueContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardTopButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0px',
  },
  cardTypeContainer: {
    padding: '10px 15px 10px 15px',
    margin: '0px 5px',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardTypeText: {
    backgroundColor: COLOR_TYPE_TEXT,
  },
  cardTypeQr: {
    backgroundColor: COLOR_TYPE_QR_CODE,
  },
  cardTypeImage: {
    backgroundColor: COLOR_TYPE_IMAGE,
  },
  square: {
    height: 24,
    width: 24,
  },
  text: {
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '0px 0px 0px 6px',
  },
  deleteButtonContainer: {
    padding: '10px 6px 10px 15px',
    margin: '0px 5px',
    border: `1px solid ${COLOR_BORDER_DELETE}`,
    borderRadius: 10,
    justifyContent: 'center',
    alignContent: 'center',
  },
  editButtonContainer: {
    padding: '10px 6px 10px 15px',
    margin: '0px 5px',
    border: `1px solid ${COLOR_PRIMARY}`,
    borderRadius: 10,
    justifyContent: 'center',
    alignContent: 'center',
  },
  cardBottomContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardBottomTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  fontColorBox: {
    width: 10,
    height: 10,
    backgroundColor: (props) => props.backgroundColor,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  colorPicker: (props) => ({
    height: 20,
    width: 10,
    marginRight: 5,
    backgroundColor: props.backgroundColor,
  }),
  annotationImage: {
    objectFit: 'contain',
  },
}));

const getTypeData = (type, classes) => {
  switch (type) {
    case PDF_TYPE_TEXT:
      return {
        color: classes.cardTypeText,
        icon: textIcon,
        text: 'Text',
      };
    case PDF_TYPE_QR_CODE:
      return {
        color: classes.cardTypeQr,
        icon: qrcodeIcon,
        text: 'QR Code',
      };
    case PDF_TYPE_IMAGE:
      return {
        color: classes.cardTypeImage,
        icon: imageIcon,
        text: 'Image',
      };
    default:
      return COLOR_BACKGROUND;
  }
};

const renderType = (type, classes) => {
  const typeData = getTypeData(type, classes);
  return (
    <div className={`${classes.cardTypeContainer} ${typeData.color} `}>
      <img alt="logo" className={classes.square} src={typeData.icon} />
      <Typography className={classes.text}>
        {typeData.text}
      </Typography>
    </div>
  );
};

const renderBottomTopField = (type, item, classes) => {
  let fields = [];

  let fontStyle = '';

  fontStyle = item.isBold ? fontStyle.concat('Bold') : fontStyle;
  fontStyle = item.isBold && item.isItalic ? fontStyle.concat(', ') : fontStyle;
  fontStyle = item.isItalic ? fontStyle.concat('Italic') : fontStyle;

  if (type === PDF_TYPE_TEXT) {
    fields = [
      {
        title: LocalizedString.annotationDesignerPage.labelPage,
        value: item.page,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelFontName,
        value: item.fontName,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelFontStyle,
        value: fontStyle,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelFontSize,
        value: item.fontSize.toString(),
      },
      {
        title: LocalizedString.annotationDesignerPage.labelRotation,
        value: item.rotation,
      },
    ];
  }

  if (type === PDF_TYPE_QR_CODE) {
    fields = [
      {
        title: LocalizedString.annotationDesignerPage.labelPage,
        value: item.page,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelCoordinateX,
        value: item.x,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelCoordinateY,
        value: item.y,
      },
    ];
  }

  if (type === PDF_TYPE_IMAGE) {
    fields = [
      {
        title: LocalizedString.annotationDesignerPage.labelPage,
        value: item.page,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelCoordinateX,
        value: item.x,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelCoordinateY,
        value: item.y,
      },
    ];
  }

  return fields.map((fieldItem, index) => (
    <Grid item xs key={`${fieldItem.id}-${index.toString()}`}>
      <Typography className={classes.cardTopTextValueTitle}>
        {fieldItem.title}
      </Typography>
      <VerticalSpacer height={10} />
      <div className={classes.textContainer}>
        <Typography className={classes.cardTopTextValueContent}>
          {fieldItem.value}
        </Typography>
      </div>
    </Grid>
  ));
};

const renderBottomBottomField = (type, item, classes) => {
  let fields = [];


  if (type === PDF_TYPE_TEXT) {
    fields = [
      {
        title: LocalizedString.annotationDesignerPage.labelColor,
        value: item.fontColor,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelCoordinateX,
        value: item.x,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelCoordinateY,
        value: item.y,
      },
      {
        title: '',
        value: '',
      },
      {
        title: '',
        value: '',
      },
    ];
  }

  if (type === PDF_TYPE_QR_CODE) {
    fields = [
      {
        title: LocalizedString.annotationDesignerPage.labelColor,
        value: item.color,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelWidth,
        value: item.width,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelHeight,
        value: item.height,
      },
    ];
  }

  if (type === PDF_TYPE_IMAGE) {
    fields = [
      {
        title: LocalizedString.annotationDesignerPage.labelWidth,
        value: item.width,
      },
      {
        title: LocalizedString.annotationDesignerPage.labelHeight,
        value: item.height,
      },
      {
        title: '',
        value: '',
      },
    ];
  }

  return fields.map((fieldItem, index) => (
    <Grid item xs key={`${fieldItem.id}-${index.toString()}`}>
      <Typography className={classes.cardTopTextValueTitle}>
        {fieldItem.title}
      </Typography>
      <VerticalSpacer height={10} />
      {
      fieldItem.title === LocalizedString.annotationDesignerPage.labelColor
        ? (
          <div className={classes.textContainer}>
            <ColorBox color={fieldItem.value} />
            <Typography className={classes.cardTopTextValueContent}>
              {fieldItem.value}
            </Typography>
          </div>
        )
        : (
          <Typography className={classes.cardTopTextValueContent}>
            {fieldItem.value}
          </Typography>
        )
      }
    </Grid>

  ));
};

const PDFList = ({
  data, onAppear, onDeletePressed, onEditPressed,
  addingEditing, onSubmitPressed, onCancelPress,
  handleSubmit, stepTitle, stepContent,
  addDialogHeaderContainerStyle, addDialogSubmitButtonCaption,
  children, onShowJSONPressed,
  onCreateNewText, onCreateNewQrCode, onCreateNewImage,
  onPreviewClicked, templatePdf, onClearClicked,
  disableClear, pageMode, onConfirmPressed,
  myConfigItems, previewingPdfTemplate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const open = Boolean(anchorEl);

  useEffect(() => {
    onAppear();
    // eslint-disable-next-line
  }, [onAppear]);

  const onCloseMoreMenu = () => {
    setAnchorEl(null);
  };

  const onOpenMoreMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSubMoreMenuPress = (itemFunction) => {
    itemFunction();
    onCloseMoreMenu();
  };

  const menuList = [
    {
      caption: 'Text',
      icon: textIconBlack,
      onPress: () => { onCreateNewText(PDF_TYPE_TEXT); },
    },
    {
      caption: 'Qr Code',
      icon: qrcodeIconBlack,
      onPress: () => { onCreateNewQrCode(PDF_TYPE_QR_CODE); },
    },
    {
      caption: 'Image',
      icon: imageIconBlack,
      onPress: () => { onCreateNewImage(PDF_TYPE_IMAGE); },
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <div className={classes.buttonContainerShow}>
          <AccentButton
            variant="outlined"
            caption={LocalizedString.annotationDesignerPage.buttonCaptionShowJSON}
            onClick={onShowJSONPressed}
            style={classes.buttonOutline}
            disabled={addingEditing}
          />

          <AccentButton
            variant="outlined"
            caption={LocalizedString.annotationDesignerPage.buttonCaptionPreview}
            disabled={!templatePdf || addingEditing}
            onClick={onPreviewClicked}
            style={classes.buttonOutline}
            loading={previewingPdfTemplate}
          />

          <AccentButton
            variant="outlined"
            caption={LocalizedString.annotationDesignerPage.buttonCaptionClear}
            onClick={onClearClicked}
            style={classes.buttonOutline}
            disabled={disableClear || pageMode === PAGE_MODE_VIEW || addingEditing}
          />
        </div>
        <div className={classes.buttonCreateContainer}>
          <AccentButton
            onClick={onOpenMoreMenu}
            endIcon={<KeyboardArrowDownOutlined />}
            caption={LocalizedString.annotationDesignerPage.buttonCaptionAddNew}
            style={classes.buttonCreate}
            disabled={pageMode === PAGE_MODE_VIEW || !templatePdf}
          />
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={onCloseMoreMenu}
            disabled={addingEditing}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            {menuList.map((item) => (
              <MenuItem
                key={item.caption}
                onClick={() => onSubMoreMenuPress(item.onPress)}
              >
                <div className={classes.menuListContainer}>
                  <img alt="logo" className={classes.square} src={item.icon} />
                  <div className={classes.horizontalSpacer} />
                  <Typography>{item.caption}</Typography>
                </div>
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>

      <div className={classes.horizontalLine} />

      {data.length > 0 ? (data.map((item) => (
        <div className={classes.cardContainer} key={item.id}>
          <div className={`${classes.colorRod} ${getTypeData(item.type, classes).color}`} />
          <div className={classes.cardContentContainer}>
            <div className={classes.cardTopContainer}>
              <div className={classes.cardTopTextContainer}>
                <Typography className={classes.cardTopTextValueTitle}>
                  {LocalizedString.annotationDesignerPage.labelValue}
                </Typography>
                <VerticalSpacer height={10} />
                <Typography className={classes.cardTopTextValueContent}>
                  {item.type === PDF_TYPE_IMAGE ? item.image : item.value}
                </Typography>
              </div>
              <div className={classes.cardTopButtonContainer}>
                {renderType(item.type, classes)}
                <AccentButton
                  onClick={() => onDeletePressed(item.id)}
                  startIcon={(
                    <DeleteOutline style={{
                      color: pageMode === PAGE_MODE_VIEW
                        ? COLOR_DISABLED_CONTROL : COLOR_BORDER_DELETE,
                    }}
                    />
)}
                  variant="textless"
                  style={classes.deleteButtonContainer}
                  disabled={pageMode === PAGE_MODE_VIEW || addingEditing}
                />
                <AccentButton
                  onClick={() => onEditPressed(item)}
                  startIcon={(
                    <EditOutlined style={{
                      color: pageMode === PAGE_MODE_VIEW
                        ? COLOR_DISABLED_CONTROL : COLOR_PRIMARY,
                    }}
                    />
)}
                  variant="textless"
                  style={classes.editButtonContainer}
                  disabled={pageMode === PAGE_MODE_VIEW || addingEditing}
                />
              </div>
            </div>
            <div className={classes.horizontalLine} />
            <div className={classes.cardBottomContainer}>
              <Grid container spacing={2}>
                {renderBottomTopField(item.type, item, classes)}
              </Grid>
              <VerticalSpacer height={10} />
              <Grid container spacing={2}>
                {renderBottomBottomField(item.type, item, classes)}
              </Grid>
              {item.type === PDF_TYPE_IMAGE ? (<img src={getImageValue(item.value)} alt="" width={150} height={150} className={classes.annotationImage} />) : null}
            </div>
          </div>
        </div>
      )))
        : (<Typography>{LocalizedString.annotationDesignerPage.labelNoComments}</Typography>)}

      <AddEditDialog
        submitting={addingEditing}
        onSubmitPressed={onSubmitPressed}
        onCancelPressed={onCancelPress}
        handleSubmit={handleSubmit}
        disabled={false}
        stepContent={stepContent}
        stepTitle={stepTitle}
        headerContainerStyle={addDialogHeaderContainerStyle}
        buttonCaption={addDialogSubmitButtonCaption}
      >
        {children}
      </AddEditDialog>

      <CodeEditorDialog
        usefullWidthDialog
      />
      <AlertBox
        onConfirmPressed={(message) => onConfirmPressed(message, history, myConfigItems)}
      />
    </div>
  );
};

export default PDFList;

PDFList.propTypes = {
  data: PropTypes.arrayOf(AnnotationShape).isRequired,
  stepTitle: PropTypes.arrayOf(PropTypes.string),
  stepContent: PropTypes.arrayOf(PropTypes.node),

  myConfigItems: PropTypes.object.isRequired,

  children: PropTypes.node.isRequired,

  addingEditing: PropTypes.bool,
  disableClear: PropTypes.bool.isRequired,
  previewingPdfTemplate: PropTypes.bool.isRequired,
  onAppear: PropTypes.func,
  onDeletePressed: PropTypes.func,
  onEditPressed: PropTypes.func,
  onSubmitPressed: PropTypes.func,
  handleSubmit: PropTypes.func,
  onCancelPress: PropTypes.func,
  onCreateNewText: PropTypes.func.isRequired,
  onCreateNewQrCode: PropTypes.func.isRequired,
  onCreateNewImage: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
  onShowJSONPressed: PropTypes.func.isRequired,
  onPreviewClicked: PropTypes.func.isRequired,
  onClearClicked: PropTypes.func.isRequired,

  addDialogHeaderContainerStyle: PropTypes.string,
  addDialogSubmitButtonCaption: PropTypes.string,
  templatePdf: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
};

PDFList.defaultProps = {
  stepTitle: [],
  stepContent: [],

  addingEditing: false,

  onAppear: () => {},
  onDeletePressed: () => {},
  onEditPressed: () => {},
  onSubmitPressed: () => {},
  onCancelPress: () => {},
  handleSubmit: () => {},

  addDialogHeaderContainerStyle: '',
  addDialogSubmitButtonCaption: '',
};
