/* eslint-disable react/jsx-props-no-spreading */
import {
  FormControl, FormHelperText, Typography, makeStyles,
} from '@material-ui/core';
import Editor from 'react-simple-code-editor';
import React, { useEffect, useState } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import theme from 'prism-react-renderer/themes/github';
import PropTypes from 'prop-types';
import { COLOR_BACKGROUND_SECONDARY, COLOR_DANGEROUS } from '../constant';
import HorizontalLine from './horizontal-line';

const useStyles = makeStyles(() => ({
  helperText: {
    color: COLOR_DANGEROUS,
    fontStyle: 'italic',
    marginLeft: 14,
  },
  templateBodyField: {
    marginBottom: '10px',
  },
  codeEditor: {
    fontFamily: '"Fira code", "Fira Mono", monospace',
    fontSize: 12,
    backgroundColor: COLOR_BACKGROUND_SECONDARY,
    marginTop: '5px',
    width: '100%',
  },
  form: {
    width: '100%',
  },
}));

const highlight = (code, language) => (
  <Highlight
    {...defaultProps}
    theme={theme}
    code={code}
    language={language}
  >
    {({
      tokens, getLineProps, getTokenProps,
    }) => (
      <>
        {tokens.map((line, i) => (
          <div {...getLineProps({ line, key: i })}>
            {line.map((token, key) => (
              <span {...getTokenProps({ token, key })} />
            ))}
          </div>
        ))}
      </>
    )}
  </Highlight>
);

const CodeEditor = ({
  label, helperText, value, error, readOnly, required, onChangeText,
  maxHeight, language,
}) => {
  const classes = useStyles();
  const [code, setCode] = useState();

  useEffect(() => {
    setCode(value || '');
  }, [value]);

  return (
    <div>
      <Typography className={classes.templateBodyField}>{label}</Typography>
      <HorizontalLine />
      <FormControl
        error={error}
        className={classes.form}
      >
        <div style={{
          ...{ overflowY: 'auto' },
          ...(maxHeight ? { maxHeight: `${maxHeight}px` } : {}),
        }}
        >
          <Editor
            value={code || ''}
            readOnly={readOnly}
            required={required}
            onValueChange={(val) => {
              onChangeText(val);
              setCode(val);
            }}
            highlight={(val) => highlight(val, language)}
            padding={10}
            className={classes.codeEditor}
          />
        </div>
        <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default CodeEditor;

CodeEditor.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string,
  language: PropTypes.string,
  maxHeight: PropTypes.number,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  onChangeText: PropTypes.func,
};

CodeEditor.defaultProps = {
  label: null,
  helperText: null,
  value: null,
  required: false,
  readOnly: false,
  error: false,
  maxHeight: null,
  language: 'markup',
  onChangeText: () => {},
};
