import {
  SET_ANALYTICS_COUNTRIES_BY_USERS, SET_ANALYTICS_DEVICES_BY_USERS,
  SET_ANALYTICS_EVENT_NAMES_COUNTS,
  SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA, SET_ANALYTICS_TOP_5_APP_VERSIONS,
  SET_ANALYTICS_TOP_5_APPS_CHART_SERIES,
  CLEAR_ANALYTICS_COUNTRIES_BY_USERS, CLEAR_ANALYTICS_DEVICES_BY_USERS,
  CLEAR_ANALYTICS_EVENT_NAMES_COUNTS,
  CLEAR_ANALYTICS_USERS_APP_VERSION_CHART_DATA, CLEAR_ANALYTICS_TOP_5_APP_VERSIONS,
  CLEAR_ANALYTICS_TOP_5_APPS_CHART_SERIES,
  SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME,
  CLEAR_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME,
  SET_ANALYTICS_CUSTOM_ERROR_MESSAGES,
  CLEAR_ANALYTICS_CUSTOM_ERROR_MESSAGES,
  SET_ANALYTICS_ACTIVE_USER_CHART_DATA,
  CLEAR_ANALYTICS_ACTIVE_USER_CHART_DATA,
  SET_ANALYTICS_TOTAL_ACTIVE_USER,
  SET_ANALYTICS_PAGE_TITLE_AND_SCREEN_NAMES,
  CLEAR_ANALYTICS_PAGE_TITLE_AND_SCREEN_NAMES,
  SET_ANALYTICS_WEBSITE_USER_ENGAGEMENTS,
  CLEAR_ANALYTICS_WEBSITE_USER_ENGAGEMENTS,
} from '../action';

const initialState = {
  activeUser: [],
  countriesByUsers: [],
  devicesByUsers: [],
  eventNamesCounts: [],
  usersAppVersionChartData: [],
  top5AppVersions: [],
  top5AppsChartSeries: [],
  userEngagementByScreenName: [],
  pageTitleAndScreenNames: [],
  customErrorMessages: {},
  totalActiveUser: '',
  websiteUserEngagements: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYTICS_TOTAL_ACTIVE_USER: {
      return { ...state, totalActiveUser: action.total };
    }
    case SET_ANALYTICS_ACTIVE_USER_CHART_DATA: {
      return { ...state, activeUser: action.data };
    }
    case SET_ANALYTICS_COUNTRIES_BY_USERS: {
      return { ...state, countriesByUsers: action.data };
    }
    case SET_ANALYTICS_DEVICES_BY_USERS: {
      return { ...state, devicesByUsers: action.data };
    }
    case SET_ANALYTICS_EVENT_NAMES_COUNTS: {
      return { ...state, eventNamesCounts: action.data };
    }
    case SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA: {
      return { ...state, usersAppVersionChartData: action.data };
    }
    case SET_ANALYTICS_TOP_5_APP_VERSIONS: {
      return { ...state, top5AppVersions: action.data };
    }
    case SET_ANALYTICS_TOP_5_APPS_CHART_SERIES: {
      return { ...state, top5AppsChartSeries: action.data };
    }
    case SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME: {
      return { ...state, userEngagementByScreenName: action.data };
    }
    case SET_ANALYTICS_CUSTOM_ERROR_MESSAGES: {
      return { ...state, customErrorMessages: action.data };
    }
    case SET_ANALYTICS_PAGE_TITLE_AND_SCREEN_NAMES: {
      return { ...state, pageTitleAndScreenNames: action.data };
    }
    case SET_ANALYTICS_WEBSITE_USER_ENGAGEMENTS: {
      return { ...state, websiteUserEngagements: action.data };
    }
    case CLEAR_ANALYTICS_ACTIVE_USER_CHART_DATA:
      return { ...state, activeUser: [] };
    case CLEAR_ANALYTICS_COUNTRIES_BY_USERS:
      return { ...state, countriesByUsers: [] };
    case CLEAR_ANALYTICS_DEVICES_BY_USERS:
      return { ...state, devicesByUsers: [] };
    case CLEAR_ANALYTICS_EVENT_NAMES_COUNTS:
      return { ...state, eventNamesCounts: [] };
    case CLEAR_ANALYTICS_USERS_APP_VERSION_CHART_DATA:
      return { ...state, usersAppVersionChartData: [] };
    case CLEAR_ANALYTICS_TOP_5_APP_VERSIONS:
      return { ...state, top5AppVersions: [] };
    case CLEAR_ANALYTICS_TOP_5_APPS_CHART_SERIES:
      return { ...state, top5AppsChartSeries: [] };
    case CLEAR_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME:
      return { ...state, userEngagementByScreenName: [] };
    case CLEAR_ANALYTICS_CUSTOM_ERROR_MESSAGES:
      return { ...state, customErrorMessages: [] };
    case CLEAR_ANALYTICS_PAGE_TITLE_AND_SCREEN_NAMES:
      return { ...state, pageTitleAndScreenNames: [] };
    case CLEAR_ANALYTICS_WEBSITE_USER_ENGAGEMENTS:
      return { ...state, websiteUserEngagements: [] };
    default:
      return state;
  }
};
