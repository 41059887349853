import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_CALENDARS = 'DOWNLOADING_CALENDARS';
export const downloadingCalendars = makeActionCreator(
  DOWNLOADING_CALENDARS,
  'status',
);

export const SET_CALENDARS = 'SET_CALENDARS';
export const setCalendars = makeActionCreator(SET_CALENDARS, 'data');

export const ADD_CALENDAR = 'ADD_CALENDAR';
export const addCalendar = makeActionCreator(ADD_CALENDAR, 'data');

export const CLEAR_CALENDARS = 'CLEAR_CALENDARS';
export const clearCalendars = makeActionCreator(CLEAR_CALENDARS);

export const ADDING_EDITING_CALENDAR = 'ADDING_EDITING_CALENDAR';
export const addingEditingCalendar = makeActionCreator(
  ADDING_EDITING_CALENDAR,
  'status',
);

export const DOWNLOADING_DELETING_CALENDAR = 'DOWNLOADING_DELETING_CALENDAR';
export const downloadingDeletingCalendar = makeActionCreator(
  DOWNLOADING_DELETING_CALENDAR,
  'status',
);

export const SET_CALENDAR_SELECTED_PAGE_SIZE = 'SET_CALENDAR_SELECTED_PAGE_SIZE';
export const setCalendarSelectedPageSize = makeActionCreator(
  SET_CALENDAR_SELECTED_PAGE_SIZE,
  'size',
);

export const SET_CALENDAR_SELECTED_ORDER_BY = 'SET_CALENDAR_SELECTED_ORDER_BY';
export const setCalendarSelectedOrderBy = makeActionCreator(
  SET_CALENDAR_SELECTED_ORDER_BY,
  'order',
);

export const SET_CALENDAR_TAPPED_ID = 'SET_CALENDAR_TAPPED_ID';
export const setCalendarTappedId = makeActionCreator(
  SET_CALENDAR_TAPPED_ID,
  'id',
);

export const SET_CALENDAR_SEARCH_TEXT = 'SET_CALENDAR_SEARCH_TEXT';
export const setCalendarSearchText = makeActionCreator(
  SET_CALENDAR_SEARCH_TEXT,
  'text',
);

export const SET_CALENDAR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CALENDAR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCalendarAdvancedFilterDialogSelectedFilterString = makeActionCreator(
  SET_CALENDAR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  'text',
);

export const SET_CALENDAR_TABLE_DATA_PARENT_CALENDARS = 'SET_CALENDAR_TABLE_DATA_PARENT_CALENDARS';
export const setCalendarTableDataParentCalendars = makeActionCreator(SET_CALENDAR_TABLE_DATA_PARENT_CALENDARS, 'data');

export const SET_CALENDAR_SELECTED_PARENT_CALENDARS = 'SET_CALENDAR_SELECTED_PARENT_CALENDARS';
export const setCalendarSelectedParentCalendars = makeActionCreator(SET_CALENDAR_SELECTED_PARENT_CALENDARS, 'option');

export const ADDING_CALENDAR_PARENT_CALENDAR = 'ADDING_CALENDAR_PARENT_CALENDAR';
export const addingCalendarParentCalendar = makeActionCreator(ADDING_CALENDAR_PARENT_CALENDAR, 'status');

export const SET_CALENDAR_SELECTED_YEAR = 'SET_CALENDAR_SELECTED_YEAR';
export const setCalendarSelectedYear = makeActionCreator(SET_CALENDAR_SELECTED_YEAR, 'year');

export const DOWNLOADING_PARENT_CALENDARS = 'DOWNLOADING_PARENT_CALENDARS';
export const downloadingParentCalendars = makeActionCreator(
  DOWNLOADING_PARENT_CALENDARS,
  'status',
);

export const SET_PARENT_CALENDARS = 'SET_PARENT_CALENDARS';
export const setParentCalendars = makeActionCreator(SET_PARENT_CALENDARS, 'data');

export const CLEAR_PARENT_CALENDARS = 'CLEAR_PARENT_CALENDARS';
export const clearParentCalendars = makeActionCreator(CLEAR_PARENT_CALENDARS);

export const SET_PARENT_CALENDARS_SEARCH_TEXT = 'SET_PARENT_CALENDARS_SEARCH_TEXT';
export const setParentCalendarsSearchText = makeActionCreator(SET_PARENT_CALENDARS_SEARCH_TEXT, 'text');

export const SET_CALENDAR_ACTIVE_TAB = 'SET_CALENDAR_ACTIVE_TAB';
export const setCalendarActiveTab = makeActionCreator(SET_CALENDAR_ACTIVE_TAB, 'text');

export const SET_SET_DATES_DIALOG_VISIBILITY = 'SET_SET_DATES_DIALOG_VISIBILITY';
export const setSetDatesDialogVisibility = makeActionCreator(SET_SET_DATES_DIALOG_VISIBILITY, 'status');

export const SET_CALENDAR_SELECTED_SET_BY = 'SET_CALENDAR_SELECTED_SET_BY';
export const setCalendarSelectedSetBy = makeActionCreator(SET_CALENDAR_SELECTED_SET_BY, 'setBy');

export const SET_CALENDAR_SELECTED_SET_AS = 'SET_CALENDAR_SELECTED_SET_AS';
export const setCalendarSelectedSetAs = makeActionCreator(SET_CALENDAR_SELECTED_SET_AS, 'setAs');

export const SET_CALENDAR_SELECTED_MONTH = 'SET_CALENDAR_SELECTED_MONTH';
export const setCalendarSelectedMonth = makeActionCreator(SET_CALENDAR_SELECTED_MONTH, 'month');

export const SET_CALENDAR_SELECTED_DAYS = 'SET_CALENDAR_SELECTED_DAYS';
export const setCalendarSelectedDays = makeActionCreator(SET_CALENDAR_SELECTED_DAYS, 'days');

export const SET_CALENDAR_SELECTED_DATE = 'SET_CALENDAR_SELECTED_DATE';
export const setCalendarSelectedDate = makeActionCreator(SET_CALENDAR_SELECTED_DATE, 'date');

export const SET_CALENDAR_SET_DATES_DESCRIPTION = 'SET_CALENDAR_SET_DATES_DESCRIPTION';
export const setCalendarSetDatesDescription = makeActionCreator(SET_CALENDAR_SET_DATES_DESCRIPTION, 'text');

export const SET_CALENDAR_SET_DATES_TAGS = 'SET_CALENDAR_SET_DATES_TAGS';
export const setCalendarSetDatesTags = makeActionCreator(SET_CALENDAR_SET_DATES_TAGS, 'text');

export const SET_CALENDAR_DAYS = 'SET_CALENDAR_DAYS';
export const setCalendarDays = makeActionCreator(SET_CALENDAR_DAYS, 'days');

export const SET_CALENDAR_DATE_INITIAL_FOCUSED_DATE = 'SET_CALENDAR_DATE_INITIAL_FOCUSED_DATE';
export const setCalendarDateInitialFocusedDate = makeActionCreator(SET_CALENDAR_DATE_INITIAL_FOCUSED_DATE, 'date');

export const DOWNLOADING_CALENDAR_DATE_RANGE_STATUS = 'DOWNLOADING_CALENDAR_DATE_RANGE_STATUS';
export const downloadingCalendarDateRangeStatus = makeActionCreator(DOWNLOADING_CALENDAR_DATE_RANGE_STATUS, 'status');

export const SET_CALENDAR_DATE_RANGE_STATUS = 'SET_CALENDAR_DATE_RANGE_STATUS';
export const setCalendarDateRangeStatus = makeActionCreator(SET_CALENDAR_DATE_RANGE_STATUS, 'data');

export const CLEAR_CALENDAR_DATE_RANGE_STATUS = 'CLEAR_CALENDAR_DATE_RANGE_STATUS';
export const clearCalendarDateRangeStatus = makeActionCreator(CLEAR_CALENDAR_DATE_RANGE_STATUS);

export const ADDING_CALENDAR_DAYS = 'ADDING_CALENDAR_DAYS';
export const addingCalendarDays = makeActionCreator(ADDING_CALENDAR_DAYS, 'status');
