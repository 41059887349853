import LocalizedString from './localization';

/* eslint-disable import/prefer-default-export */
export const rxformValidateAnnotationDesigner = (values) => {
  let errors = {};
  const pageValue = values?.page || 0;
  const fontSizeValue = values?.fontSize || 0;
  const imageValue = values?.imageValue || values?.value || '';

  if (pageValue <= 0) {
    errors = {
      ...errors,
      page: LocalizedString.annotationDesignerPage.labelPageMustBeGreater,
    };
  }
  if (fontSizeValue <= 0) {
    errors = {
      ...errors,
      fontSize: LocalizedString.annotationDesignerPage.labelFontSizeMustBeGreater,
    };
  }
  if (!imageValue) {
    errors = {
      ...errors,
      imageValue: LocalizedString.annotationDesignerPage.labelImageIsRequired,
    };
  }
  return errors;
};
