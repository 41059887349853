import PropTypes from 'prop-types';

export * from '../../type';

export const AnnotationShape = PropTypes.shape({
  title: PropTypes.string,
  type: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontName: PropTypes.string,
  fontStyle: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rotation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  coordinateX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  coordinateY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
});
