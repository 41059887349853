import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useLocation } from 'react-router-dom';
import {
  Grid, Typography, makeStyles, IconButton,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import {
  COLOR_PRIMARY,
  PAGE_MODE_VIEW,
  IMAGE_FILE_EXTENSIONS,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFORM_ANNOTATION_DESIGNER, PDF_TYPE_TEXT,
  PDF_TYPE_QR_CODE, PDF_TYPE_IMAGE, FONT_STYLE,
  RXFIELD_ANNOTATION_DESIGNER_VALUE, RXFIELD_ANNOTATION_DESIGNER_FONT_NAME,
  RXFIELD_ANNOTATION_DESIGNER_COLOR, RXFIELD_ANNOTATION_DESIGNER_COORDINATE_X,
  RXFIELD_ANNOTATION_DESIGNER_COORDINATE_Y, RXFIELD_ANNOTATION_DESIGNER_ROTATION,
  RXFIELD_ANNOTATION_DESIGNER_PAGE, RXFIELD_ANNOTATION_DESIGNER_FONT_SIZE,
  RXFIELD_ANNOTATION_DESIGNER_FONT_STYLE, RXFIELD_ANNOTATION_DESIGNER_WIDTH,
  RXFIELD_ANNOTATION_DESIGNER_HEIGHT, RXFIELD_ANNOTATION_DESIGNER_IMAGE,
  RXFIELD_ANNOTATION_DESIGNER_FONT_COLOR,
  RXSTATE_ANNOTATION_DESIGNER_PAGE,
  RXSTATE_ANNOTATION_DESIGNER,
  PAGE_MODE_TABLE,
  PAGE_MODE_ADD,
  PAGE_MODE_EDIT,
  RXFIELD_ANNOTATION_DESIGNER_NAME,
  RXFIELD_ANNOTATION_DESIGNER_DESCRIPTION,
  COLOR_DANGEROUS,
  COLOR_TEXT_ON_PRIMARY,
  ANALYTICS_LOG_EDIT,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormColorPickerField,
  renderReduxFormCheckboxOption,
  renderReduxFormDropzonePicker,
} from '../../../../redux-form-rendererer';
import { toMoment, logEventToAnalytics } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { rxformValidateAnnotationDesigner } from '../../validation';
import {
  BasePage, PDFList, UploadPDF, FunctionalPage,
  AccentButton,
} from '../../component';
import {
  FormInitialValueShape, SimpleDataShape, AnnotationShape,
} from '../../type';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    color: COLOR_PRIMARY,
    fontWeight: 500,
  },
  editDetailheaderField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  primaryButtonContainer: {
    padding: '4px',
    marginRight: '12px',
  },
  primaryButton: {
    color: COLOR_PRIMARY,
    fontSize: '20px',
  },
  editDetailtitle: {
    color: COLOR_PRIMARY,
    marginRight: 35,
    fontWeight: 500,
  },
  form: {
    display: 'flex',
    flex: 1,
  },
  buttonDialogContainer: {
    justifyContent: 'center',
  },
  buttonText: {
    color: COLOR_DANGEROUS,
    margin: '10px',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
    padding: '0px 10px',
  },
  formContentField: {
    display: 'flex',
    flex: 1,
    maxWidth: '50%',
    flexDirection: 'column',
  },
  formContentButton: {
    display: 'flex',
    flexDirection: 'column',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const renderField = (
  type, initialValues, addingEditing, pageMode,
  defaultFontValue, onFontOptionSelected,
  onImageSelected, selectedImage, onColorSelected, fontData,
) => {
  switch (type) {
    case PDF_TYPE_TEXT:
      return (
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_VALUE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderValue}
                label={LocalizedString.annotationDesignerPage.placeholderValue}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_FONT_NAME}
                component={renderReduxFormOutlinedDropdownTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderFontName}
                label={LocalizedString.annotationDesignerPage.placeholderFontName}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                data={fontData}
                value={defaultFontValue}
                onOptionSelected={onFontOptionSelected}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_FONT_COLOR}
                component={renderReduxFormColorPickerField}
                label={LocalizedString.annotationDesignerPage.placeholderColor}
                value={initialValues.fontColor}
                onChangePressed={onColorSelected}
              />
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_X}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                      label={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_Y}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                      label={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      multiline
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_PAGE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderPage}
                label={LocalizedString.annotationDesignerPage.placeholderPage}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_FONT_SIZE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderFontSize}
                label={LocalizedString.annotationDesignerPage.placeholderFontSize}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                multiline
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_FONT_STYLE}
                component={renderReduxFormCheckboxOption}
                label={LocalizedString.annotationDesignerPage.placeholderFontStyle}
                data={FONT_STYLE}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                horizontal
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_ROTATION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderRotation}
                label={LocalizedString.annotationDesignerPage.placeholderRotation}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      );
    case PDF_TYPE_QR_CODE:
      return (
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_VALUE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderValue}
                label={LocalizedString.annotationDesignerPage.placeholderValue}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_X}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                label={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_WIDTH}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderWidth}
                label={LocalizedString.annotationDesignerPage.placeholderWidth}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_COLOR}
                component={renderReduxFormColorPickerField}
                label={LocalizedString.annotationDesignerPage.placeholderColor}
                value={initialValues.color}
                onChangePressed={onColorSelected}
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_PAGE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderPage}
                label={LocalizedString.annotationDesignerPage.placeholderPage}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_Y}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                label={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                multiline
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_HEIGHT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderHeight}
                label={LocalizedString.annotationDesignerPage.placeholderHeight}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                multiline
                required
              />
            </Grid>
          </Grid>
        </Grid>
      );
    case PDF_TYPE_IMAGE:
      return (
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_PAGE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderPage}
                label={LocalizedString.annotationDesignerPage.placeholderPage}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_X}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                      label={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_Y}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                      label={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      multiline
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_WIDTH}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderWidth}
                      label={LocalizedString.annotationDesignerPage.placeholderWidth}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_HEIGHT}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderHeight}
                      label={LocalizedString.annotationDesignerPage.placeholderHeight}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      multiline
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_IMAGE}
                component={renderReduxFormDropzonePicker}
                label={LocalizedString.annotationDesignerPage.placeholderImage}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                loading={addingEditing}
                onImageSelected={onImageSelected}
                onBlur={(e) => e.preventDefault()}
                defaultValue={initialValues.imageValue || initialValues.value || ''}
                imageFileExtension={IMAGE_FILE_EXTENSIONS}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      );
    default: return (<></>);
  }
};

const renderDialogContent = (
  pdfType, initialValues, addingEditing, pageMode,
  defaultFontValue, onFontOptionSelected,
  onImageSelected, selectedImage, onColorSelected,
  fontData,
) => (
  <Grid>
    {renderField(pdfType, initialValues, addingEditing, pageMode,
      defaultFontValue, onFontOptionSelected,
      onImageSelected, selectedImage, onColorSelected, fontData)}
  </Grid>
);

const AnnotationDesignerPage = ({
  data, fontData, onAppear, initialValues, onImageSelected, selectedImage, pdfType,
  addingEditing, pageMode, onSaveAnnotationPressed, onDeleteListPressed, onEditPressed,
  onColorSelected, onFontOptionSelected, handleSubmit, onConfirmDeletePressed,
  downloading, onCancelPressed, onChangePage, onChangePageSize, onRefresh,
  onSearchBarTextChanged, onSortPressed, onCreatePressed, onBackPressed,
  onEditButtonPressed, onViewButtonPressed, onSavePressed, onChangeNameText,
  onChangeDescriptionText, tappedId, onDeletePressed, downloadingDeleting, onCancelEditPressed,
  editPermission, editPermissionName,
}) => {
  const [reloading, setReloading] = useState(false);
  const classes = useStyles();
  const location = useLocation();

  const onCancelPress = () => {
    onCancelPressed();
    onBackPressed();
  };

  const onBackPress = () => {
    if (pageMode === PAGE_MODE_ADD || pageMode === PAGE_MODE_VIEW) {
      onBackPressed();
    } else if (pageMode === PAGE_MODE_EDIT) { onCancelPress(); }
  };

  if (pageMode === PAGE_MODE_TABLE) {
    return (
      <FunctionalPage
        data={RXSTATE_ANNOTATION_DESIGNER}
        uiPage={RXSTATE_ANNOTATION_DESIGNER_PAGE}
        tableColumns={[
          {
            title: LocalizedString.annotationDesignerPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.annotationDesignerPage.labelName, field: 'name', sorting: !downloading },
          {
            title: LocalizedString.annotationDesignerPage.labelCreatedDate,
            field: 'createdDate',
            sorting: !downloading,
            render: ({ createdDate }) => (createdDate
              ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          },
        ]}
        handleSubmit={handleSubmit}
        onAppear={onAppear}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onCreatePressed={onCreatePressed}
        onConfirmDeletePressed={onConfirmDeletePressed}
        onDeletePressed={onDeletePressed}
        onEditPressed={onEditButtonPressed}
        onRefresh={onRefresh}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewButtonPressed}
        createNewButtonCaption={
          LocalizedString.annotationDesignerPage.buttonCaptionCreateNewAnnotation
        }
        deleteButtonCaption={LocalizedString.annotationDesignerPage.buttonCaptionDeleteAnnotation}
        editButtonCaption={LocalizedString.annotationDesignerPage.buttonCaptionEditAnnotation}
        title={LocalizedString.annotationDesignerPage.title}
        addDialogHeaderContainerStyle={classes.dialogHeader}
        downloadingDeleting={downloadingDeleting}
        useFullWidth
        createPermissionName="DOCGEN_CREATE_PDF_ANNOTATION_DESIGN"
        editPermissionName={editPermissionName}
        deletePermissionName="DOCGEN_DELETE_PDF_ANNOTATION_DESIGN"
      />
    );
  }

  return (
    <BasePage>
      <div className={classes.editDetailheaderField}>
        <IconButton onClick={onBackPress} className={classes.primaryButtonContainer}>
          <ArrowBack className={classes.primaryButton} />
        </IconButton>
        <Typography variant="h5" className={classes.editDetailtitle}>
          {LocalizedString.annotationDesignerPage.title}
        </Typography>
      </div>

      <div className={classes.container}>
        <div className={classes.formContainer}>
          <form
            onSubmit={handleSubmit(onSavePressed)}
            className={classes.form}
            id="data"
          >
            <div className={classes.formContentField}>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderName}
                label={LocalizedString.annotationDesignerPage.placeholderName}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                onChangeText={(e) => onChangeNameText(e)}
                required
              />

              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_DESCRIPTION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderDescription}
                label={LocalizedString.annotationDesignerPage.placeholderDescription}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                onChangeText={(e) => onChangeDescriptionText(e)}
                required
                multiline
              />
            </div>
          </form>
          <div className={classes.formContentButton}>
            {pageMode !== PAGE_MODE_VIEW && (
              <>
                <AccentButton
                  variant="contained"
                  type="submit"
                  className={classes.button}
                  loading={addingEditing}
                  disableElevation
                  caption={GlobalLocalizedString.common.buttonCaptionSave}
                  disabled={addingEditing}
                  size="small"
                  form="data"
                />

                <AccentButton
                  onClick={async () => {
                    setReloading(true);
                    await onCancelEditPressed();
                    setReloading(false);
                  }}
                  variant="text"
                  caption={GlobalLocalizedString.common.buttonCaptionCancel}
                  className={classes.buttonText}
                  disabled={addingEditing}
                  size="small"
                  loading={reloading}
                />
              </>
            )}
            {pageMode === PAGE_MODE_VIEW && editPermission && (
              <AccentButton
                variant="contained"
                type="button"
                onClick={() => {
                  onEditButtonPressed(tappedId);
                  logEventToAnalytics(location, ANALYTICS_LOG_EDIT);
                }}
                caption={GlobalLocalizedString.common.buttonCaptionEdit}
                loading={addingEditing}
              />
            )}
          </div>
        </div>
        <div className={classes.rowContainer}>
          <PDFList
            data={data}
            onAppear={onAppear}
            onDeletePressed={onDeleteListPressed}
            onSubmitPressed={onSaveAnnotationPressed}
            onEditPressed={onEditPressed}
            handleSubmit={handleSubmit}
          >
            {renderDialogContent(
              pdfType, initialValues, addingEditing, pageMode,
              initialValues.fontName, onFontOptionSelected,
              onImageSelected, selectedImage, onColorSelected,
              fontData,
            )}
          </PDFList>
          <UploadPDF />
        </div>
      </div>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_ANNOTATION_DESIGNER,
  validate: rxformValidateAnnotationDesigner,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AnnotationDesignerPage);

AnnotationDesignerPage.propTypes = {
  data: PropTypes.arrayOf(AnnotationShape).isRequired,
  fontData: PropTypes.arrayOf(SimpleDataShape).isRequired,
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  editPermission: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onBackPressed: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onFontOptionSelected: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSaveAnnotationPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onColorSelected: PropTypes.func.isRequired,
  onCancelEditPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeNameText: PropTypes.func.isRequired,
  onChangeDescriptionText: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDeleteListPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEditButtonPressed: PropTypes.func.isRequired,
  onViewButtonPressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  editPermissionName: PropTypes.string.isRequired,
  selectedImage: PropTypes.string.isRequired,
  pdfType: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
