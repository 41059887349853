import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Typography, makeStyles, IconButton, Grid,
  Fab, Paper,
} from '@material-ui/core';
import {
  CalendarMultiple, Calendar,
} from 'mdi-material-ui';
import {
  ArrowBack,
} from '@material-ui/icons';
import {
  COLOR_PRIMARY,
  COLOR_BACKGROUND,
  PAGE_MODE_VIEW,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  PAGE_MODE_TABLE,
  PAGE_MODE_ADD,
  PAGE_MODE_EDIT,
  COLOR_DANGEROUS,
  COLOR_TEXT_ON_PRIMARY,
  RXSTATE_CALENDARS,
  RXSTATE_CALENDAR_PAGE,
  RXFORM_CALENDAR,
  RXFIELD_CALENDAR_CALENDAR_DESCRIPTION,
  RXFIELD_CALENDAR_CALENDAR_NAME,
  RXFIELD_CALENDAR_CALENDAR_PARENT_CALENDARS,
  RXFIELD_CALENDAR_SELECTED_YEAR,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME,
  TYPES_FILTER,
  ANALYTICS_LOG_EDIT,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormAutocompleteTableField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { toMoment, logEventToAnalytics } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  BasePage, FunctionalPage, AccentButton, HorizontalSpacer,
  SetDatesDialog, StyledTab, CombinedView, IndividualView,
} from '../../component';
import { SimpleDataShape, FormInitialValueShape } from '../../type';
import { rxformValidateCalendar } from '../../validation';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 80,
  },
  title: {
    color: COLOR_PRIMARY,
    fontWeight: 500,
  },
  editDetailheaderField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  primaryButtonContainer: {
    padding: '4px',
    marginRight: '12px',
  },
  primaryButton: {
    color: COLOR_PRIMARY,
    fontSize: '20px',
  },
  editDetailtitle: {
    color: COLOR_PRIMARY,
    marginRight: 35,
    fontWeight: 500,
  },
  form: {
    display: 'flex',
    flex: 1,
  },
  buttonText: {
    color: COLOR_DANGEROUS,
    margin: '10px',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowSpaceBetween: {
    justifyContent: 'space-between',
  },
  rowAlignCenter: {
    alignItems: 'center',
  },
  justifyFlexEnd: {
    justifyContent: 'flex-end',
  },
  marginRightSmall: {
    marginRight: '8px',
  },
  calendarLegendBox: {
    width: 20,
    height: 20,
    borderRadius: 4,
  },
  calendarLegendWorkingDay: {
    backgroundColor: 'green',
  },
  calendarLegendHoliday: {
    backgroundColor: 'red',
  },
  calendarLegendUndefined: {
    backgroundColor: 'white',
    border: '1px solid black',
  },
  paper: {
    transform: 'translateZ(0px)',
  },
  floatingContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '12px 35px 12px 35px',
    backgroundColor: COLOR_BACKGROUND,
    borderRadius: 5,
    boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.16)',
    height: 86,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    zIndex: 1,
    '&:hover': {
      backgroundColor: COLOR_BACKGROUND,
    },
  },
  activityIndicator: {
    margin: 'auto',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const renderTabDetailContent = (initialValues, tabs, activeTab, classes) => {
  let component;
  switch (activeTab) {
    case tabs[0].label: component = (<IndividualView />); break;
    case tabs[1].label: component = (<CombinedView />); break;
    default: break;
  }

  return (
    <Paper className={classes.contentContainer}>
      {component}
    </Paper>
  );
};

const CalendarPage = ({
  onAppear, addingEditing, pageMode, handleSubmit, onConfirmDeletePressed,
  downloading, onCancelPressed, onChangePage, onChangePageSize, onRefresh,
  onSearchBarTextChanged, onSortPressed, onCreatePressed, onBackPressed,
  onViewButtonPressed, onSavePressed, tappedId, onDeletePressed, downloadingDeleting,
  parentCalendars, addingParentCalendar, tableDataParentCalendars, loadingParentCalendars,
  selectedParentCalendars, tappedParentCalendar, onParentCalendarsOptionSelected,
  onAddParentCalendarsPressed, onChangeParentCalendarsText, onDeleteParentCalendarsPressed,
  initialValues, years, onShowYearOptionSelected, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onSetDatesButtonPressed, setDatesDialogVisibility, onEditPressed, users, loadingUser,
  onChangeUserText, activeTab, onTabPressed, searchBarText, filterString, selectedPageSize,
  orderBy, selectedPageNo, initFilterString, advancedFilterForm, downloadingDateRangeStatus,
  deletePermission, editPermission, deletePermissionName, editPermissionName,
}) => {
  const [isFromDetail, setIsFromDetail] = useState(false);
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    onAppear(isFromDetail, searchBarText, filterString, selectedPageSize, orderBy,
      selectedPageNo, initFilterString, advancedFilterForm, setIsFromDetail);
  },
  // eslint-disable-next-line
  [isFromDetail]);

  const onBackPress = () => {
    if (pageMode === PAGE_MODE_ADD || pageMode === PAGE_MODE_VIEW || pageMode === PAGE_MODE_EDIT) {
      setIsFromDetail(true);
      onBackPressed();
    }
  };

  if (pageMode === PAGE_MODE_TABLE) {
    return (
      <FunctionalPage
        data={RXSTATE_CALENDARS}
        uiPage={RXSTATE_CALENDAR_PAGE}
        filterColumns={[
          {
            title: LocalizedString.calendarPage.labelCreatedDate,
            field: 'createdDate',
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.calendarPage.labelType,
            field: 'type',
            type: FILTER_TYPE_DROPDOWN,
            data: TYPES_FILTER,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.calendarPage.labelUser,
            field: 'user',
            type: FILTER_TYPE_DROPDOWN,
            data: users,
            loading: loadingUser,
            onChangeFilterText: onChangeUserText,
            useDropdownValue: true,
          },
        ]}
        tableColumns={[
          {
            title: LocalizedString.calendarPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.calendarPage.labelName, field: 'name', sorting: !downloading },
          { title: LocalizedString.calendarPage.labelDescription, field: 'description', sorting: !downloading },
          {
            title: LocalizedString.calendarPage.labelCreatedDate,
            field: 'createdDate',
            sorting: !downloading,
            render: ({ createdDate }) => (createdDate
              ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          },
        ]}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onCancelPressed={() => { setIsFromDetail(true); onCancelPressed(); }}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onCreatePressed={onCreatePressed}
        onConfirmDeletePressed={onConfirmDeletePressed}
        onDeletePressed={onDeletePressed}
        onEditPressed={onEditPressed}
        onRefresh={onRefresh}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewButtonPressed}
        createNewButtonCaption={
          LocalizedString.calendarPage.buttonCaptionCreateNewCalendar
        }
        deleteButtonCaption={LocalizedString.calendarPage.buttonCaptionDeleteCalendar}
        editButtonCaption={LocalizedString.calendarPage.buttonCaptionEditCalendar}
        title={LocalizedString.calendarPage.title}
        addDialogHeaderContainerStyle={classes.dialogHeader}
        useFullWidth
        createPermissionName="CALENDAR_CREATE_CALENDAR"
        editPermissionName={editPermissionName}
        deletePermissionName={deletePermissionName}
      />
    );
  }

  const tabs = [
    {
      label: LocalizedString.calendarPage.labelIndividualView,
      hasPermission: true,
      iconName: Calendar,
    },
    {
      label: LocalizedString.calendarPage.labelCombinedlView,
      hasPermission: true,
      iconName: CalendarMultiple,
    },
  ];

  return (
    <BasePage onConfirmPressed={onConfirmDeletePressed}>
      <div className={classes.editDetailheaderField}>
        <IconButton onClick={onBackPress} className={classes.primaryButtonContainer}>
          <ArrowBack className={classes.primaryButton} />
        </IconButton>
        <Typography variant="h5" className={classes.editDetailtitle}>
          {LocalizedString.calendarPage.title}
        </Typography>
      </div>

      <div className={classes.container}>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_CALENDAR_CALENDAR_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.calendarPage.labelName}
                label={LocalizedString.calendarPage.labelName}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                required
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_CALENDAR_CALENDAR_DESCRIPTION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.calendarPage.labelDescription}
                label={LocalizedString.calendarPage.labelDescription}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                multiline
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_CALENDAR_CALENDAR_PARENT_CALENDARS}
                component={renderReduxFormAutocompleteTableField}
                label={LocalizedString.calendarPage.labelParentCalendars}
                currentTableData={tableDataParentCalendars}
                defaultValue={initialValues.parents}
                options={parentCalendars}
                adding={addingParentCalendar}
                disableAdd={pageMode === PAGE_MODE_VIEW}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                loadingOptions={loadingParentCalendars}
                onAddPressed={(defaultValue, newInitialValue, selectedOption,
                  currentData) => onAddParentCalendarsPressed(defaultValue, newInitialValue,
                  selectedOption, currentData)}
                onChangeOptionText={(text) => onChangeParentCalendarsText(text)}
                onDeletePressed={(newValue, currentData) => onDeleteParentCalendarsPressed(
                  newValue, currentData,
                )}
                onOptionSelected={onParentCalendarsOptionSelected}
                selectedOption={selectedParentCalendars}
                optionPlaceholder={LocalizedString.calendarPage.labelParentCalendars}
                currentTappedData={tappedParentCalendar}
                onBlur={(e) => e.preventDefault()}
                tableColumns={[
                  {
                    title: LocalizedString.calendarPage.labelOrder,
                    field: 'order',
                    sorting: false,
                  },
                  {
                    title: LocalizedString.calendarPage.labelCalendarName,
                    field: 'label',
                    sorting: false,
                  },
                ]}
              />
            </Grid>
            <Grid item>
              <div className={`${classes.row} ${classes.rowSpaceBetween} ${classes.rowAlignCenter}`}>
                <div>
                  <div className={`${classes.row} ${classes.rowAlignCenter}`}>
                    <div className={`${classes.calendarLegendBox} ${classes.calendarLegendWorkingDay}`} />
                    <HorizontalSpacer width={8} />
                    <Typography variant="body1">
                      {LocalizedString.calendarPage.labelWorkingDay}
                    </Typography>
                  </div>
                  <div className={`${classes.row} ${classes.rowAlignCenter}`}>
                    <div className={`${classes.calendarLegendBox} ${classes.calendarLegendHoliday}`} />
                    <HorizontalSpacer width={8} />
                    <Typography variant="body1">
                      {LocalizedString.calendarPage.labelHoliday}
                    </Typography>
                  </div>
                  <div className={`${classes.row} ${classes.rowAlignCenter}`}>
                    <div className={`${classes.calendarLegendBox} ${classes.calendarLegendUndefined}`} />
                    <HorizontalSpacer width={8} />
                    <Typography variant="body1">
                      {LocalizedString.calendarPage.labelUndefined}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div className={`${classes.row} ${classes.rowAlignCenter}`}>
                    <Typography variant="body1">
                      {LocalizedString.calendarPage.labelShowYear}
                    </Typography>
                    <Field
                      name={RXFIELD_CALENDAR_SELECTED_YEAR}
                      component={renderReduxFormSimpleDropdownField}
                      value={initialValues?.selectedYear}
                      data={years}
                      onOptionSelected={(option) => onShowYearOptionSelected(option, activeTab)}
                      disabled={downloadingDateRangeStatus}
                    />
                  </div>
                  <div className={`${classes.row} ${classes.justifyFlexEnd} ${classes.marginRightSmall}`}>
                    {pageMode !== PAGE_MODE_VIEW && (
                      <AccentButton
                        variant="contained"
                        type="button"
                        className={classes.button}
                        caption={LocalizedString.calendarPage.buttonCaptionSetDates}
                        onClick={() => onSetDatesButtonPressed(initialValues?.selectedYear)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 32 }}>
                {pageMode === PAGE_MODE_ADD || pageMode === PAGE_MODE_EDIT ? (
                  <IndividualView />
                ) : (
                  <>
                    <StyledTab
                      data={tabs}
                      onTabPressed={(tab) => onTabPressed(tab, activeTab)}
                      activeTab={activeTab}
                      disabled={downloadingDateRangeStatus || downloadingDeleting}
                    />
                    {renderTabDetailContent(initialValues, tabs, activeTab, classes)}
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Fab
        className={classes.floatingContainer}
        disableFocusRipple
        disableTouchRipple
        disableRipple
      >
        {pageMode === PAGE_MODE_VIEW ? (
          <div>
            {deletePermission && (
            <AccentButton
              onClick={() => onDeletePressed(tappedId)}
              variant="text"
              caption={LocalizedString.calendarPage.buttonCaptionDeleteCalendar}
              className={classes.buttonText}
              disabled={downloadingDeleting}
              size="small"
            />
            )}
            {editPermission && (
            <AccentButton
              onClick={() => {
                onEditPressed(tappedId);
                logEventToAnalytics(location, ANALYTICS_LOG_EDIT);
              }}
              variant="contained"
              caption={LocalizedString.calendarPage.buttonCaptionEditCalendar}
              className={classes.button}
            />
            )}
          </div>
        ) : (
          <div className={classes.row}>
            <AccentButton
              onClick={onCancelPressed}
              variant="text"
              caption={GlobalLocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              disabled={addingEditing}
              size="small"
            />
            <AccentButton
              variant="contained"
              type="submit"
              className={classes.button}
              caption={GlobalLocalizedString.common.buttonCaptionSave}
              disabled={addingEditing}
              loading={addingEditing}
              onClick={handleSubmit(onSavePressed)}
            />
          </div>
        )}
      </Fab>
      {setDatesDialogVisibility && (<SetDatesDialog />)}
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_CALENDAR,
  validate: rxformValidateCalendar,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  shouldValidate: () => true,
})(CalendarPage);

CalendarPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  selectedParentCalendars: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  tableDataParentCalendars: PropTypes.arrayOf(SimpleDataShape).isRequired,
  parentCalendars: PropTypes.arrayOf(SimpleDataShape).isRequired,
  tappedParentCalendar: FormInitialValueShape,
  advancedFilterForm: FormInitialValueShape.isRequired,
  initialValues: FormInitialValueShape.isRequired,
  years: PropTypes.arrayOf(PropTypes.object).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingParentCalendars: PropTypes.bool.isRequired,
  addingParentCalendar: PropTypes.bool.isRequired,
  setDatesDialogVisibility: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  downloadingDateRangeStatus: PropTypes.bool.isRequired,
  deletePermission: PropTypes.bool.isRequired,
  editPermission: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onBackPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onViewButtonPressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onParentCalendarsOptionSelected: PropTypes.func.isRequired,
  onAddParentCalendarsPressed: PropTypes.func.isRequired,
  onChangeParentCalendarsText: PropTypes.func.isRequired,
  onDeleteParentCalendarsPressed: PropTypes.func.isRequired,
  onShowYearOptionSelected: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onSetDatesButtonPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
  deletePermissionName: PropTypes.string.isRequired,
  editPermissionName: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  selectedPageSize: PropTypes.number.isRequired,
  selectedPageNo: PropTypes.number.isRequired,
  searchBarText: PropTypes.string.isRequired,
  filterString: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  initFilterString: PropTypes.string.isRequired,
};

CalendarPage.defaultProps = {
  tappedParentCalendar: {},
  selectedParentCalendars: null,
};
