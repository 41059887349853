import { connect } from 'react-redux';
import { reset } from 'redux-form';
import UploadPDFPage from './upload-pdf.presentation';
import { readFile, transformPdfUrl } from '../../helper';
import { setAlertErrorMessage, setTemplateTotalPages, uploadPDFTemplateAsync } from '../../redux/action';
import { REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFORM_UPLOAD_PDF } from '../../constant';
import {
  setPDFFileName,
  setPDFUrl, setUploadingPDFVisibility,
} from '../../redux/action/simple-action';
import LocalizedString from '../../localization';

const getTemplatePdf = (state) => {
  const { uiUploadPdf } = state;
  const { templatePdf } = uiUploadPdf;

  const url = templatePdf ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${templatePdf}` : '';

  return url;
};

const mapStateToProps = (state) => ({
  visibility: state.uiUploadPdf.visibility,
  pageMode: state.uiFunctionalPage.pageMode,
  uploadingPdfTemplate: state.uiUploadPdf.uploadingPdfTemplate,
  templatePdf: getTemplatePdf(state),
  totalPages: state.uiUploadPdf.totalPages,
  addingEditing: state.uiAnnotationDesign.addingEditing,
  downloadingDeleting: state.uiAnnotationDesignList.downloadingDeleting,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setPDFUrl(''));
  },
  onFileChange: async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl = await readFile(file);
      const url = transformPdfUrl(imageDataUrl);

      dispatch(setPDFFileName(file.name));
      dispatch(setPDFUrl(url));
      dispatch(uploadPDFTemplateAsync())
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    }
  },
  onDocumentLoadSuccess: (numPages) => {
    dispatch(setTemplateTotalPages(numPages));
  },
  onDocumentLoadError: (error) => {
    if (error.name === 'MissingPDFException') {
      const errorMessage = {
        ...error,
        message: LocalizedString.annotationDesignerPage.errorMessagePDFExpired,
      };
      dispatch(setAlertErrorMessage(errorMessage));
    } else {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onUploadButtonPressed: () => {
    dispatch(reset(RXFORM_UPLOAD_PDF));
    dispatch(setUploadingPDFVisibility(true));
  },
  onCanceledPressed: () => {
    dispatch(setUploadingPDFVisibility(false));
  },
  onClosePressed: () => {
    dispatch(setUploadingPDFVisibility(false));
  },
  onSavePressed: (values) => {
    dispatch(uploadPDFTemplateAsync(values))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPDFPage);
