import React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY } from '../constant';

const useStyles = makeStyles(() => ({
  outlined: {
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
  },
  text: {
    color: COLOR_PRIMARY,
  },
  contained: {
    background: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    borderRadius: 50,
    fontSize: 12,
  },
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const AccentButton = ({
  loading, variant, size, caption, style, ...props
}) => {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.activityIndicator}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  if (variant === 'outlined') {
    return (
      <Button
        variant="outlined"
        className={`${classes.outlined} ${style}`}
        size={size}
        {...props}
      >
        {caption}
      </Button>
    );
  }
  if (variant === 'text') {
    return (
      <Button
        variant="text"
        className={`${classes.text} ${style}`}
        size={size}
        {...props}
      >
        {caption}
      </Button>
    );
  }
  if (variant === 'textless') {
    return (
      <Button
        variant="outlined"
        className={`${classes.outlined} ${style}`}
        size={size}
        {...props}
      >
        {caption}
      </Button>
    );
  }
  return (
    <Button
      variant="contained"
      className={`${classes.contained} ${style}`}
      size={size}
      {...props}
    >
      {caption}
    </Button>
  );
};

export default AccentButton;

AccentButton.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'text', 'contained', 'textless']),
  caption: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.string,
};

AccentButton.defaultProps = {
  loading: false,
  variant: 'contained',
  caption: '',
  size: 'medium',
  style: '',
};
