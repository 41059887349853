import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    calendarPage: {
      title: 'Calendar',

      labelNo: 'No.',
      labelName: 'Name',
      labelDescription: 'Description',
      labelCreatedDate: 'Created Date',
      labelParentCalendars: 'Parent Calendars',
      labelOrder: 'Order',
      labelCalendarName: 'Calendar Name',
      labelShowYear: 'Show Year',
      labelWorkingDay: 'Working Day',
      labelHoliday: 'Holiday',
      labelUndefined: 'Undefined',
      labelSetDates: 'Set Dates',
      labelSetBy: 'Set By',
      labelSetAs: 'Set As',
      labelMonths: 'Months',
      labelDate: 'Date',
      labelType: 'Type',
      labelUser: 'User',
      labelIndividualView: 'Individual View',
      labelCombinedlView: 'Combined View',
      labelTags: 'Tags',

      buttonCaptionCreateNewCalendar: 'Create New Calendar',
      buttonCaptionDeleteCalendar: 'Delete Calendar',
      buttonCaptionEditCalendar: 'Edit Calendar',
      buttonCaptionSetDates: 'Set Dates',
      buttonCaptionPreview: 'Preview',
    },
  },
  id: {
    calendarPage: {
      title: 'Kalender',

      labelNo: 'No.',
      labelName: 'Nama',
      labelDescription: 'Deskripsi',
      labelCreatedDate: 'Tanggal Dibuat',
      labelParentCalendars: 'Kalender Utama',
      labelOrder: 'Urutan',
      labelCalendarName: 'Nama Kalendar',
      labelShowYear: 'Tampilkan Tahun',
      labelWorkingDay: 'Hari Kerja',
      labelHoliday: 'Liburan',
      labelUndefined: 'Belum Diartikan',
      labelSetDates: 'Tetapkan Tanggal',
      labelSetBy: 'Ditetapkan Oleh',
      labelSetAs: 'Tetapkan Sebagai',
      labelMonths: 'Bulan',
      labelDate: 'Tanggal',
      labelType: 'Tipe',
      labelUser: 'Pengguna',
      labelIndividualView: 'Tampilan Individu',
      labelCombinedlView: 'Tampilan Gabungan',
      labelTags: 'Penanda',

      buttonCaptionCreateNewCalendar: 'Buat Kalender',
      buttonCaptionDeleteCalendar: 'Hapus Kalender',
      buttonCaptionEditCalendar: 'Ubah Kalendar',
      buttonCaptionSetDates: 'Tetapkan Tanggal',
      buttonCaptionPreview: 'Pratinjau',
    },
  },
});

export default LocalizedString;
