import { connect } from 'react-redux';
import { change } from 'redux-form';
import moment from 'moment';
import {
  PAGE_MODE_TABLE,
  RXFORM_CALENDAR_SET_DATES_DIALOG,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_BY,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_AS,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_MONTHS,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DATE,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DESCRIPTION,
  SET_BY_WEEKDAY,
  SET_BY_INDIVIDUAL_DATE,
  SET_AS_UNDEFINED,
  REVERSED_ISO_DATE_FORMAT,
  RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_TAGS,
} from '../../constant';
import {
  transformInitialValues, getDates, toMoment,
} from '../../helper';
import {
  setSetDatesDialogVisibility,
  setCalendarSelectedDate,
  setCalendarSelectedMonth,
  setCalendarSelectedSetAs,
  setCalendarSelectedSetBy,
  setCalendarSelectedDays,
  setCalendarSetDatesDescription,
  setCalendarDays,
  addingCalendarDays,
  setAlertErrorMessage,
  setCalendarSetDatesTags,
} from '../../redux/action';
import SetDatesDialog from './set-dates-dialog.presentation';

const getInitialValues = (state) => {
  const { calendars, uiCalendar, uiFunctionalPage } = state;
  const {
    tappedId, selectedSetBy, selectedSetAs, selectedMonth,
    selectedDays, selectedDate, setDatesDescription,
    setDatesTags,
  } = uiCalendar;
  const { pageMode } = uiFunctionalPage;

  const found = tappedId !== '' && pageMode !== PAGE_MODE_TABLE
    ? calendars.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0 ? transformInitialValues(found, {
    setBy: selectedSetBy || '',
    setAs: selectedSetAs || '',
    months: selectedMonth || '',
    days: selectedDays || '',
    date: selectedDate || '',
    setDatesDescription: setDatesDescription || '',
    setDatesTags: setDatesTags || '',
  }) : {
    setBy: '',
    setAs: '',
    months: '',
    days: '',
    date: '',
    setDatesDescription: '',
    setDatesTags: '',
  };
  return initVal;
};

const getYears = () => {
  const year = new Date().getFullYear();
  const firstYear = year - 5 - 1;
  return Array.from({ length: 11 }, (v, i) => {
    const yearItem = firstYear + i + 1;
    return { label: yearItem.toString(), value: yearItem };
  });
};

const getMonths = () => {
  const months = ['AllMonths'];
  const momentMonths = moment.months();
  return [...months, ...momentMonths];
};

const getDays = () => {
  const weekdays = moment.weekdays();
  const transformedWeekdays = [...weekdays.slice(1), weekdays[0]];
  return transformedWeekdays.map((weekday) => ({ label: weekday, value: weekday }));
};

const transformDaysPaylaod = (state) => {
  const { uiCalendar } = state;
  const { days } = uiCalendar;

  const result = days.map((day) => ({ ...day, date: day.date.split('T')[0] }));
  return result;
};

const mapStateToProps = (state) => {
  const { selectedYear } = state.uiCalendar;
  const defaultYear = new Date().getFullYear();
  const selectedYearValue = selectedYear !== null
    ? selectedYear : { label: defaultYear.toString(), value: defaultYear };

  return {
    pageMode: state.uiFunctionalPage.pageMode,
    addingEditing: state.uiCalendar.addingEditing,
    downloading: state.uiCalendar.downloading,
    downloadingDeleting: state.uiCalendar.downloadingDeleting,
    initialValues: getInitialValues(state),
    years: getYears(),
    months: getMonths(),
    days: getDays(),
    selectedSetBy: state.uiCalendar.selectedSetBy,
    selectedSetAs: state.uiCalendar.selectedSetAs,
    selectedMonth: state.uiCalendar.selectedMonth,
    selectedDays: state.uiCalendar.selectedDays,
    selectedDate: state.uiCalendar.selectedDate,
    setDatesDescription: state.uiCalendar.setDatesDescription,
    setDatesTags: state.uiCalendar.setDatesTags,
    daysPayload: transformDaysPaylaod(state),
    dateInitialFocusedDate: state.uiCalendar.dateInitialFocusedDate,
    selectedYear: selectedYearValue,
    addingCalendarDays: state.uiCalendar.addingCalendarDays,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSetDatesDialogCancelPressed: () => {
    dispatch(setSetDatesDialogVisibility(false));
  },
  onSetByOptionSelected: (option) => {
    if (option) {
      dispatch(setCalendarSelectedSetBy(option));
      dispatch(change(
        RXFORM_CALENDAR_SET_DATES_DIALOG, RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_BY, option,
      ));
    } else {
      dispatch(setCalendarSelectedSetBy(''));
      dispatch(change(
        RXFORM_CALENDAR_SET_DATES_DIALOG, RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_BY, '',
      ));
    }
  },
  onSetAsOptionSelected: (option) => {
    if (option) {
      dispatch(setCalendarSelectedSetAs(option));
      dispatch(change(
        RXFORM_CALENDAR_SET_DATES_DIALOG, RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_AS, option,
      ));
    } else {
      dispatch(setCalendarSelectedSetAs(''));
      dispatch(change(
        RXFORM_CALENDAR_SET_DATES_DIALOG, RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_SET_AS, '',
      ));
    }
  },
  onMonthOptionSelected: (option) => {
    if (option) {
      dispatch(setCalendarSelectedMonth(option));
      dispatch(change(
        RXFORM_CALENDAR_SET_DATES_DIALOG, RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_MONTHS, option,
      ));
    } else {
      dispatch(setCalendarSelectedMonth(''));
      dispatch(change(
        RXFORM_CALENDAR_SET_DATES_DIALOG, RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_MONTHS, '',
      ));
    }
  },
  onDayOptionSelected: (days) => {
    dispatch(setCalendarSelectedDays(days));
  },
  onDateChanged: (date) => {
    dispatch(setCalendarSelectedDate(date));
    dispatch(change(
      RXFORM_CALENDAR_SET_DATES_DIALOG, RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DATE, date,
    ));
  },
  onSetDatesDescriptionTextChanged: (text) => {
    dispatch(setCalendarSetDatesDescription(text));
    dispatch(change(
      RXFORM_CALENDAR_SET_DATES_DIALOG,
      RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_DESCRIPTION,
      text,
    ));
  },
  onOkButtonPressed: async (
    selectedSetBy,
    selectedSetAs,
    selectedMonth,
    selectedDays,
    selectedDate,
    setDateDescription,
    selectedYear,
    daysPayload,
    setDatesTags,
  ) => {
    try {
      dispatch(addingCalendarDays(true));

      if (!selectedSetBy || !selectedSetAs) {
        dispatch(setSetDatesDialogVisibility(false));
        return;
      }

      if (
        daysPayload.length > 0
        && selectedSetBy?.value === SET_BY_INDIVIDUAL_DATE
        && selectedSetAs?.value === SET_AS_UNDEFINED
        && selectedDate
      ) {
        const formattedSelecetdDate = toMoment(selectedDate).format(REVERSED_ISO_DATE_FORMAT);
        const filteredDaysPayload = daysPayload.filter((
          item,
        ) => item.date !== formattedSelecetdDate);
        dispatch(setCalendarDays(filteredDaysPayload));
        dispatch(setSetDatesDialogVisibility(false));
        return;
      }

      let days = [...daysPayload];
      let datesResult = [];
      if (selectedSetBy?.value === SET_BY_WEEKDAY) {
        if (!selectedSetAs || !selectedMonth || !selectedDays) {
          dispatch(setSetDatesDialogVisibility(false));
          return;
        }

        const dayNames = selectedDays ? selectedDays.split(',') : [];
        const startMonth = selectedMonth === 'AllMonths' ? 'January' : selectedMonth;
        const endMonth = selectedMonth === 'AllMonths' ? 'December' : selectedMonth;

        dayNames.forEach((dayName) => {
          const dates = getDates(
            selectedYear?.value,
            startMonth,
            endMonth,
            dayName,
            selectedSetAs?.value,
            setDateDescription,
            setDatesTags,
          );
          datesResult = [...datesResult, ...dates];
        });
        if (selectedSetAs?.value === SET_AS_UNDEFINED) {
          const filteredDates = days.filter(
            (item1) => datesResult.filter((item2) => item2.date === item1.date).length === 0,
          );
          days = [...filteredDates];
        } else {
          days = [...days, ...datesResult].filter(
            (item, index, self) => index === self.findLastIndex((s) => (s.date === item.date)),
          );
        }
        dispatch(setCalendarDays(days));
      } else if (selectedSetBy?.value === SET_BY_INDIVIDUAL_DATE) {
        if (!selectedSetAs || !selectedDate) {
          dispatch(setSetDatesDialogVisibility(false));
          return;
        }
        const formattedSelecetdDate = toMoment(selectedDate).format(REVERSED_ISO_DATE_FORMAT);
        days = [...days, {
          date: formattedSelecetdDate,
          type: selectedSetAs?.value,
          description: setDateDescription,
          tags: setDatesTags,
        }].filter(
          (item, index, self) => index === self.findLastIndex((s) => (s.date === item.date)),
        );
        dispatch(setCalendarDays(days));
      }
      dispatch(setSetDatesDialogVisibility(false));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    } finally {
      dispatch(addingCalendarDays(false));
    }
  },
  onSetDatesTagsTextChanged: (text) => {
    dispatch(setCalendarSetDatesTags(text));
    dispatch(change(
      RXFORM_CALENDAR_SET_DATES_DIALOG,
      RXFIELD_CALENDAR_CALENDAR_SET_DATES_DIALOG_TAGS,
      text,
    ));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetDatesDialog);
