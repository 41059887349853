import { connect } from 'react-redux';
import moment from 'moment';
import ActiveUserChart from './active-users-chart.presentation';
import { getLineChartFormattedData, getNDaysAgo, toMoment } from '../../helper';
import { ANALYTICS_DATE_ONE_MONTH_AGO, ANALYTICS_DATE_TODAY, REVERSED_ISO_DATE_FORMAT } from '../../constant';
import {
  setAnalyticsActiveUserDateRanges, setActiveUserFilterDialogVisibility, setAlertErrorMessage,
  downloadAnalyticsActiveUsersAsync, clearAnalyticsActiveUserChartData,
} from '../../redux/action';

const getActiveUsersDateRange = (state) => {
  const { activeUserDateRange } = state.uiAnalytics;

  let dateFrom = activeUserDateRange.from;
  let dateTo = activeUserDateRange.to;

  if (activeUserDateRange.from === ANALYTICS_DATE_ONE_MONTH_AGO) {
    dateFrom = getNDaysAgo(30);
  }

  if (activeUserDateRange.to === ANALYTICS_DATE_TODAY) {
    dateTo = getNDaysAgo(0);
  }

  return { from: dateFrom, to: dateTo };
};

const getChartData = (state) => {
  const { activeUser } = state.analytics;
  const { activeUserDateRange } = state.uiAnalytics;

  const dateRanges = getActiveUsersDateRange(state);
  const daysDifference = moment.duration(moment(dateRanges.to).diff(dateRanges.from)).asDays() + 1;
  const daysCompareArr = [];
  for (let i = 0; i < daysDifference; i += 1) {
    daysCompareArr[i] = {
      day: i.toString(),
      total: 0,
    };
  }
  const activeUsers = daysCompareArr.map((day) => {
    const find = activeUser.find((user) => user.day === day.day);
    if (find) return find;
    return day;
  });

  return getLineChartFormattedData(activeUsers, activeUserDateRange);
};

const mapStateToProps = (state) => ({
  chartData: getChartData(state),
  totalActiveUser: state.analytics.totalActiveUser,
  downloading: state.uiAnalytics.downloadingActiveUser,
  activeUserFilterDialogVisibility:
    state.uiAnalytics.activeUserFilterDialogVisibility,
  activeUsersDateRange: getActiveUsersDateRange(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(clearAnalyticsActiveUserChartData());
  },
  onFilterIconPressed: () => {
    dispatch(setActiveUserFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setActiveUserFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsActiveUserDateRanges({
      from: ANALYTICS_DATE_ONE_MONTH_AGO,
      to: ANALYTICS_DATE_TODAY,
    }));
    dispatch(downloadAnalyticsActiveUsersAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setActiveUserFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (dateRange) => {
    dispatch(setAnalyticsActiveUserDateRanges({
      from: toMoment(dateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      to: toMoment(dateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsActiveUsersAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setActiveUserFilterDialogVisibility(false));
  },
  onChangeStartDate: (date, setDateRange, dateRange) => {
    const startDate = toMoment(date);
    const endDate = toMoment(date).add(1, 'months');
    const isDateToWithin30Days = toMoment(dateRange.to)
      .isBetween(startDate, endDate, null, '[]');

    setDateRange({
      from: date,
      to: isDateToWithin30Days ? dateRange.to : endDate.format(REVERSED_ISO_DATE_FORMAT),
    });
  },
  onChangeEndDate: (date, setDateRange, dateRange) => {
    const startDate = toMoment(date);
    const endDate = toMoment(date).subtract(1, 'months');
    const isDateFromWithin30Days = toMoment(dateRange.from)
      .isBetween(endDate, startDate, null, '[]');

    setDateRange({
      from: isDateFromWithin30Days ? dateRange.from : endDate.format(REVERSED_ISO_DATE_FORMAT),
      to: date,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveUserChart);
