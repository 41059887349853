import {
  loggingIn, addToken, addCurrentUser, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setAlertInfoMessage, setAlertInputMessage, setAlertWarningMessage,
  setDrawerVisibility,
  clearCaptcha,
} from '../simple-action';
import { login, transformConfigValueBoolean } from '../../../helper';
import LocalizedString from '../../../localization';
import { MENUID_DASHBOARD, ROUTE_NAME_DASHBOARD } from '../../../module/attendance';
import downloadMyConfigItemsAsync from '../../../module/configuration/redux/action/async/downloadMyConfigItemsAsync';

export default (username, password, captchaText, navigateTo) => async (dispatch, getState) => {
  if (!username || !password) {
    throw new Error(LocalizedString.loginScreen.errEmptyUsernamePassword);
  }

  if (!transformConfigValueBoolean(getState().myConfigItems?.auth?.useCaptchaForLoginApi || '')) {
    await dispatch(clearCaptcha());
  }

  const { data } = getState().captcha;

  try {
    dispatch(loggingIn(true));
    const auth = await login(username, password, data?.id, captchaText);
    const { token, user } = auth;

    if (!user.permissions.WEB_ADMIN_LOGIN) {
      throw new Error(LocalizedString.loginScreen.errMsgNoPermissionToLogin);
    }

    dispatch(addToken(token));
    dispatch(addCurrentUser(user));

    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setActiveSideMenuItem(MENUID_DASHBOARD));
    dispatch(setDrawerVisibility(true));
    navigateTo(ROUTE_NAME_DASHBOARD);
  } finally {
    dispatch(setAlertErrorMessage(''));
    dispatch(setAlertInfoMessage(''));
    dispatch(setAlertWarningMessage(''));
    dispatch(setAlertConfirmationMessage(''));
    dispatch(setAlertInputMessage('', ''));
    dispatch(loggingIn(false));
  }
};
